import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Button,
	Box,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	TextField as TextField2,
} from '@material-ui/core';
import { Field, Form, Formik, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	setBookingFormData,
	setPayment,
	setSelectedFlight,
	togglePGModal,
	togglePriceChangeModal,
	setBookingFormDataReturn,
} from '../reducers/UiReducer';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	FlightBookEtravDispatch,
	FlightBookDispatch,
	FlightTicketLCCDispatch,
	FlightAddBookingDispatch,
	FlightTicketLCCDispatchReturn,
	FlightTicketLCC1WDispatch,
} from '../reducers/HomeReducer';
import { walletBalanceAgentDispatch } from '../reducers/UserReducer';
import { FlightFareQuoteTripJackDispatch } from '../reducers/TripjackReducer';
import etravBooking from './EtravBookingForm';
import tbobooking from './TBOBookingLCCForm';
import tbobookingnonlcc from './TBOBookingNonLCC';
import addBooking from './FormAddBooking';
import FullScreenSeat from '../components/reusableComponents/FullScreenSeat';
import { flightDebitAmount } from '../reducers/HomeReducer';
import {
	FlightFareQuoteDispatch,
	FlightFareQuoteEtravDispatch,
} from '../reducers/HomeReducer';
import WorldlinePG from '../components/reusableComponents/WorldlinePG';
import addBookingReturn from './FormAddBookingReturn';
import addINtBooking from './FormIntAddBooking';
import countryName from './CountryName';
import { FlightBookTripJackDispatch } from '../reducers/TripjackReducer';
import EaseBuzzModal from '../components/reusableComponents/EaseBuzzModal';
const title = ['Mr', 'Mrs', 'Ms', 'Mstr', 'Miss'];
const titleC = ['Mstr', 'Miss'];
const titleI = ['Mr', 'Ms', 'Mstr', 'Miss'];
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		// border: '1px solid #959595',
		'& input': {
			border: '1px solid #959595',
		},
		backgroundColor: 'white',
		marginBottom: 30,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
		},
		'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: 5,
		},
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 5,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: 'rgb(239, 51, 57)',
		color: 'white',
		borderRadius: 5,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: 'rgb(239, 51, 57)',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	date: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
			width: '100%',
		},
	},
	dob: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	passport: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	root: {
		'& .MuiFormHelperText-root': {
			fontSize: 12,
		},
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
	},
	scroll: {
		'&::-webkit-scrollbar': { width: '20px' },
	},
	root2: {
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
	},
}));
const FlightConfirmForm = () => {
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedOrigin, selectedDepart, selectedSeatItem } = useSelector(
		(state) => state.ui
	);
	const [pay, setPay] = useState(false);
	const { agentlogin } = useSelector((state) => state.user);
	const { farequotetripjack } = useSelector((state) => state.tripjack);
	const { payment, baggageInfo, mealInfo, priceSection } = useSelector(
		(state) => state.ui
	);
	const { loading, ssrTBO } = useSelector((state) => state.home);
	const { walletagent } = useSelector((state) => state.user);
	const { fareQuoteEtrav, fareQuote, fareQuoteReturn } = useSelector(
		(state) => state.home
	);
	const date = new Date();
	let etrav2 = location.state.selectedonward
		? location.state.selectedonward[0].Airline_Code &&
		  location.state.selectedReturn[0].Airline_Code
		: '';
	let etrvotbor = location.state.selectedonward
		? location.state.selectedonward[0].Airline_Code &&
		  location.state.selectedReturn[0].AirlineCode
		: '';
	let etravrtboo = location.state.selectedonward
		? location.state.selectedReturn[0].Airline_Code &&
		  location.state.selectedonward[0].AirlineCode
		: '';
	let tbo2 = location.state.selectedonward
		? location.state.selectedonward[0].AirlineCode &&
		  location.state.selectedReturn[0].AirlineCode
		: '';
	const getActualFormat = (given: number) => {
		if (given < 10) {
			return 0 + '' + given.toString();
		} else {
			return given;
		}
	};
	useEffect(() => {
		if (location.state.OneflightData) {
			if (location.state.OneflightData.Airline_Code) {
				let formEtravFare = {
					AdultCount: location.state.body.AdultCount,
					ChildCount: location.state.body.ChildCount,
					InfantCount: location.state.body.InfantCount,
					Search_Key: location.state.IDEtrav,
					AirRepriceRequests: [
						{
							Flight_Key: location.state.OneflightData.Flight_Key,
							Fare_Id: location.state.OneflightData.Fares.Fare_Id,
						},
					],
					Customer_Mobile: location.state.formData.phone,
					GST_Input: false,
					SinglePricing: true,
					agent_id:
						agentlogin && agentlogin.status === 200
							? agentlogin.result.result.id
							: 0,
				};

				dispatch(FlightFareQuoteEtravDispatch(formEtravFare));
				dispatch(
					walletBalanceAgentDispatch(
						agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.email,
						agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.id
					)
				);
			} else if (location.state.OneflightData.AirlineCode) {
				let formData1 = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.OneflightData.ResultIndex,
					agent_id:
						agentlogin && agentlogin.status === 200
							? agentlogin.result.result.id
							: 0,
				};
				let ssr = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.OneflightData.ResultIndex,
				};

				dispatch(FlightFareQuoteDispatch(formData1, ssr, dispatch));
			} else if (location.state.OneflightData.Supplier === 'TJK') {
				let formData = {
					priceIds: [location.state.OneflightData.totalPriceList.id],
					agent_id:
						agentlogin && agentlogin.status === 200
							? agentlogin.result.result.id
							: 0,
				};
				dispatch(FlightFareQuoteTripJackDispatch(formData));
			}
		} else if (location.state.intFlight) {
			if (location.state.intFlight.Supplier === 'TBO') {
				let formData1 = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.intFlight.ResultIndex,
					agent_id:
						agentlogin && agentlogin.status === 200
							? agentlogin.result.result.id
							: 0,
				};
				let ssr = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.intFlight.ResultIndex,
				};

				dispatch(FlightFareQuoteDispatch(formData1, ssr, dispatch));
			} else if (location.state.intFlight.Supplier === 'ETR') {
				let formEtravFare = {
					AdultCount: location.state.returnBody.AdultCount,
					ChildCount: location.state.returnBody.ChildCount,
					InfantCount: location.state.returnBody.InfantCount,
					Search_Key: location.state.IDEtrav,
					AirRepriceRequests: [
						{
							Flight_Key: location.state.intFlight.Flight_Key,
							Fare_Id: location.state.intFlight.Fares.Fare_Id,
						},
					],
					Customer_Mobile: location.state.formData.phone,
					GST_Input: false,
					SinglePricing: true,
					agent_id:
						agentlogin && agentlogin.status === 200
							? agentlogin.result.result.id
							: 0,
				};

				dispatch(FlightFareQuoteEtravDispatch(formEtravFare));
				dispatch(
					walletBalanceAgentDispatch(
						agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.email,
						agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.id
					)
				);
			}
		}
	}, []);
	if (loading) {
		console.log('loading.........');
	} else {
		if (location.state.OneflightData) {
			if (
				fareQuoteEtrav?.result ||
				fareQuote?.result ||
				farequotetripjack?.result
			) {
				if (fareQuoteEtrav?.result) {
					if (
						Number(
							Math.round(
								fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight.Fares
									.Total_Amount
							)
						) >
						Number(
							Math.round(location.state.OneflightData?.Fares?.Total_Amount)
						)
					) {
						if (priceSection) {
							dispatch(togglePriceChangeModal(true));
						}
					} else {
						console.log('first');
					}
				}
				if (fareQuote?.result) {
					if (
						Number(
							Math.round(fareQuote?.result.Response.Results.Fare.Total_Amount)
						) >
						Number(Math.round(location.state.OneflightData?.Fare?.Total_Amount))
					) {
						if (priceSection) {
							dispatch(togglePriceChangeModal(true));
						}
					} else {
						console.log('first');
					}
				}
				if (farequotetripjack?.result) {
					if (
						Number(
							Math.round(
								farequotetripjack?.result.totalPriceInfo.totalFareDetail
									.Total_Amount
							)
						) >
						Number(
							Math.round(
								location.state.OneflightData?.totalPriceList.fd.Total_Amount
							)
						)
					) {
						if (priceSection) {
							dispatch(togglePriceChangeModal(true));
						}
					} else {
						console.log('first');
					}
				}
			}
		} else if (location.state.selectedReturn) {
			if (tbo2) {
				if (
					Number(
						Number(fareQuote?.result?.Response?.Results?.Fare?.Total_Amount) +
							Number(
								fareQuoteReturn?.result?.Response?.Results?.Fare?.Total_Amount
							)
					) >
					Number(
						Number(location.state.selectedonward[0].Fare.Total_Amount) +
							Number(location.state.selectedReturn[0].Fare.Total_Amount)
					)
				) {
					if (priceSection) {
						dispatch(togglePriceChangeModal(true));
					}
				}
			}
		} else if (location.state.intFlight) {
			if (fareQuote?.result) {
				if (
					Number(
						Math.round(fareQuote?.result.Response.Results.Fare.Total_Amount)
					) > Number(Math.round(location.state.intFlight?.Fare?.Total_Amount))
				) {
					if (priceSection) {
						dispatch(togglePriceChangeModal(true));
					}
				} else {
					console.log('first');
				}
			}
		}
	}
	let BookingFormData = [];
	if (location.state.formData.adults?.length > 0) {
		location.state.formData.adults.map((item) => {
			BookingFormData.push({
				...item,
				PaxType: 1,
				ticketNumber: '',
			});
		});
	}
	if (location.state.formData.childs?.length > 0) {
		location.state.formData.childs.map((item) => {
			BookingFormData.push({
				...item,
				PaxType: 2,
				ticketNumber: '',
			});
		});
	}
	if (location.state.formData.infants?.length > 0) {
		location.state.formData.infants.map((item) => {
			BookingFormData.push({
				...item,
				PaxType: 3,
				ticketNumber: '',
			});
		});
	}
	const invoice = [
		date.getFullYear(),
		getActualFormat(date.getMonth() + 1),
		getActualFormat(date.getDate()),
		getActualFormat(date.getHours()),
		getActualFormat(date.getMinutes()),
		getActualFormat(date.getSeconds()),
	].join('');
	const [offline, setOffline] = useState(false);
	const [payworldline, setPayWorldline] = useState(false);
	let agentInfo = {
		email:
			agentlogin && agentlogin.status === 200
				? agentlogin.result.result.email
				: '',
		id:
			agentlogin && agentlogin.status === 200
				? agentlogin.result.result.id
				: '',
	};
	console.table('BookingFormData', BookingFormData);
	// console.table('BookingFormDataSeat', BookingFormDataSeat);
	const handlePayment = (response) => {
		dispatch(togglePGModal(false));
		if (location.state.OneflightData?.Supplier === 'ETR') {
			etravBooking(
				location,
				response,
				invoice,
				dispatch,
				agentInfo,
				fareQuoteEtrav,
				payment,
				FlightBookEtravDispatch,
				history,
				FlightTicketLCCDispatch,
				FlightBookDispatch,
				agentlogin,
				BookingFormData,
				flightDebitAmount
			);
		} else if (location.state.OneflightData?.Supplier === 'TBO') {
			if (location.state.OneflightData?.IsLCC) {
				tbobooking(
					location,
					response,
					invoice,
					dispatch,
					agentInfo,
					fareQuote,
					payment,
					FlightBookEtravDispatch,
					history,
					FlightTicketLCC1WDispatch,
					FlightBookDispatch,
					agentlogin,
					BookingFormData,
					baggageInfo,
					mealInfo,
					flightDebitAmount
				);
			} else if (location.state.OneflightData?.IsLCC === false) {
				tbobookingnonlcc(
					location,
					response,
					invoice,
					dispatch,
					agentInfo,
					fareQuote,
					payment,
					FlightBookEtravDispatch,
					history,
					FlightTicketLCCDispatch,
					FlightBookDispatch,
					BookingFormData,
					flightDebitAmount
				);
			}
		} else if (location.state.OneflightData?.Supplier === 'TJK') {
			let formDataTJK = {
				orderId: location.state.orderID,
				paymentId: payment === 'offline' ? invoice : invoice,
				bookingId: farequotetripjack.result.bookingId,
				paymentInfos: [
					{
						amount: Number(
							farequotetripjack.result.totalPriceInfo.totalFareDetail.fC.TF
						),
					},
				],
				travellerInfo: BookingFormData.map((item, index) => {
					return {
						ti: item.title,
						fN: item.firstname,
						lN: item.lastname,
						pt:
							item.PaxType == 1
								? 'ADULT'
								: item.PaxType == 2
								? 'CHILD'
								: item.PaxType == 3
								? 'INFANT'
								: '',
						dob: item.dob ? item.dob : '1996-04-09',
						pNat: item.nationality ? item.nationality : 'IN',
						pid: '2021-09-08',
						pNum: item.passportnumber ? item.passportnumber : null,
						eD: item.expiry ? item.expiry : null,
						ssrBaggageInfos: item.baggage
							? [
									{
										key: item.baggage?.amount,
										code: item.baggage?.code,
									},
							  ]
							: [],
						ssrMealInfos: item.meal
							? [
									{
										key: item.meal?.amount,
										code: item.meal?.code,
									},
							  ]
							: [],
					};
				}),
				gstInfo: {
					gstNumber: '',
					email: '',
					registeredName: '',
					mobile: '',
					address: '',
				},
				deliveryInfo: {
					emails: [location.state.formData.email],
					contacts: [location.state.formData.phone],
				},
			};
			if (payment === 'offline') {
				let debit = {
					orderId: location.state.orderID,
					paymentId:
						payment === 'offline' ? invoice : response.razorpay_payment_id,
				};
				dispatch(flightDebitAmount(debit));
				dispatch(
					walletBalanceAgentDispatch(
						agentlogin && agentlogin.result.result.email,
						agentlogin && agentlogin.result.result.id
					)
				);
			}
			dispatch(FlightBookTripJackDispatch(formDataTJK, history));
		}
		if (location.state.selectedonward) {
			if (etrav2) {
				if (payment === 'offline') {
					let debit = {
						orderId: location.state.orderID,
						paymentId:
							payment === 'offline' ? invoice : response.razorpay_payment_id,
					};
					dispatch(flightDebitAmount(debit));
				}
				let formEtrav = {
					Customer_Mobile: location.state.formData.phone,
					Passenger_Mobile: location.state.formData.phone,
					orderId: location.state.orderID,
					paymentId:
						payment === 'offline' ? invoice : response.razorpay_payment_id,
					WhatsAPP_Mobile: null,
					Passenger_Email: location.state.formData
						? location.state.formData.email
						: '',
					PAX_Details: BookingFormData.map((item, index) => {
						return {
							Pax_Id: index + 1,
							Pax_type: item.PaxType - 1,
							Title: item.title,
							First_Name: item.firstname,
							Last_Name: item.lastname,
							Gender: 0,
							Age: null,
							DOB: item.dob ? item.dob : '1998-03-09',
							Passport_Number: item.passportnumber ? item.passportnumber : null,
							Passport_Issuing_Country: null,
							Passport_Expiry: item.expiry ? item.expiry : null,
							Nationality: null,
							FrequentFlyerDetails: null,
						};
					}),

					GST: true,
					GST_Number: '',
					GST_HolderName: 'GST Holder Name',
					GST_Address: 'GST Address',
					BookingFlightDetails: fareQuoteEtrav.result.AirRepriceResponses.map(
						(item) => {
							return {
								Search_Key: location.state.IDEtrav,
								Flight_Key: item.Flight.Flight_Key,
								BookingSSRDetails: [],
							};
						}
					),
					CostCenterId: 0,
					ProjectId: 0,
					BookingRemark: `${location.state.returnBody.Segments[0].Origin}-${location.state.returnBody.Segments[0].Destination} ${location.state.returnBody.Segments[0].PreferredDepartureTime} ${invoice}`,
					CorporateStatus: 0,
					CorporatePaymentMode: 0,
					MissedSavingReason: null,
					CorpTripType: null,
					CorpTripSubType: null,
					TripRequestId: null,
					BookingAlertIds: null,
				};
				// console.log("first",formEtrav)
				dispatch(
					FlightBookEtravDispatch(
						formEtrav,
						history,
						payment,
						location.state,
						agentlogin
					)
				);
			} else if (tbo2) {
				if (payment === 'offline') {
					let debit = {
						orderId: location.state.orderID,
						paymentId:
							payment === 'offline' ? invoice : response.razorpay_payment_id,
					};
					dispatch(flightDebitAmount(debit));
				}
				if (location.state.selectedonward[0]?.IsLCC) {
					let formDataLCC = {
						orderId: location.state.orderID,
						paymentId: payment === 'offline' ? invoice : invoice,
						TraceId: location.state.IDTBO,
						ResultIndex: location.state.selectedonward[0].ResultIndex,
						Passengers: BookingFormData.map((item) => {
							return {
								Title: item.title,
								FirstName: item.firstname,
								LastName: item.lastname,
								PaxType: item.PaxType,
								DateOfBirth: item.dob ? item.dob : '1998-03-09',
								Gender: 1,
								PassportNo: item.passportnumber ? item.passportnumber : '',
								PassportExpiry: item.expiry ? item.expiry : '',
								AddressLine1: 'Dhanbad',
								AddressLine2: '',
								Fare: {
									Currency: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.Currency
										: '',
									BaseFare: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.OfferedFare
										: '',
									Tax: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.Tax
										: '',
									YQTax: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.YQTax
										: '',
									AdditionalTxnFeePub: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.AdditionalTxnFeePub
										: '',
									AdditionalTxnFeeOfrd: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.AdditionalTxnFeeOfrd
										: '',
									OtherCharges: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.OtherCharges
										: '',
									Discount: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.Discount
										: '',
									PublishedFare: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.PublishedFare
										: '',
									OfferedFare: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.OfferedFare
										: '',
									TdsOnCommission: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.TdsOnCommission
										: '',
									TdsOnPLB: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.TdsOnPLB
										: '',
									TdsOnIncentive: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.TdsOnIncentive
										: '',
									ServiceFee: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.ServiceFee
										: '',
								},
								City: 'Dhanbad',
								CountryCode: 'IN',
								CellCountryCode: '+91',
								ContactNo: location.state.formData
									? location.state.formData.phone
									: '',
								Nationality: item.nationality ? item.nationality : 'IN',
								Email: location.state.formData
									? location.state.formData.email
									: '',
								IsLeadPax: true,
								FFAirlineCode: null,
								FFNumber: '',
								GSTCompanyAddress:
									'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
								GSTCompanyContactNumber: '8877909555',
								GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
								GSTNumber: '20AALCR5274P1ZV',
								GSTCompanyEmail: 'sales@musafirbazar.com',
							};
						}),
						Baggage: [],
						MealDynamic: [],
						SeatDynamic: [],
					};
					console.log('FormData', formDataLCC);
					dispatch(
						FlightTicketLCCDispatch(
							formDataLCC,
							history,
							location.state,
							agentlogin
						)
					);
				} else if (location.state.selectedonward[0]?.IsLCC === false) {
					let formData = {
						TraceId: location.state.IDTBO,
						ResultIndex: location.state.selectedonward[0].ResultIndex,
						orderId: location.state.orderID,
						paymentId: payment === 'offline' ? invoice : invoice,
						Passengers: BookingFormData.map((item) => {
							return {
								Title: item.title,
								FirstName: item.firstname,
								LastName: item.lastname,
								PaxType: item.PaxType,
								DateOfBirth: item.dob ? item.dob : '1998-03-09',
								Gender: 1,
								PassportNo: item.passportnumber ? item.passportnumber : '',
								PassportExpiry: item.expiry ? item.expiry : '',
								AddressLine1: 'Dhanbad',
								AddressLine2: '',
								Fare: {
									Currency: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.Currency
										: '',
									BaseFare: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.OfferedFare
										: '',
									Tax: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.Tax
										: '',
									YQTax: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.YQTax
										: '',
									AdditionalTxnFeePub: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.AdditionalTxnFeePub
										: '',
									AdditionalTxnFeeOfrd: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.AdditionalTxnFeeOfrd
										: '',
									OtherCharges: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.OtherCharges
										: '',
									Discount: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.Discount
										: '',
									PublishedFare: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.PublishedFare
										: '',
									OfferedFare: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.OfferedFare
										: '',
									TdsOnCommission: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.TdsOnCommission
										: '',
									TdsOnPLB: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.TdsOnPLB
										: '',
									TdsOnIncentive: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.TdsOnIncentive
										: '',
									ServiceFee: location.state.selectedonward[0]
										? location.state.selectedonward[0].Fare.ServiceFee
										: '',
								},
								City: 'Dhanbad',
								CountryCode: 'IN',
								CellCountryCode: '+91',
								ContactNo: location.state.formData
									? location.state.formData.phone
									: '',
								Nationality: item.nationality ? item.nationality : 'IN',
								Email: location.state.formData
									? location.state.formData.email
									: '',
								IsLeadPax: true,
								FFAirlineCode: null,
								FFNumber: '',
								GSTCompanyAddress:
									'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
								GSTCompanyContactNumber: '8877909555',
								GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
								GSTNumber: '20AALCR5274P1ZV',
								GSTCompanyEmail: 'sales@musafirbazar.com',
							};
						}),
						Baggage: [],
						MealDynamic: [],
						SeatDynamic: [],
					};
					dispatch(
						FlightBookDispatch(
							formData,
							history,
							location.state.IDTBO,
							location
						)
					);
				}
				if (location.state.selectedReturn[0]?.IsLCC) {
					let formDataLCC = {
						TraceId: location.state.IDTBO,
						ResultIndex: location.state.selectedReturn[0].ResultIndex,
						orderId: location.state.orderID,
						paymentId: payment === 'offline' ? invoice : invoice,
						Passengers: BookingFormData.map((item) => {
							return {
								Title: item.title,
								FirstName: item.firstname,
								LastName: item.lastname,
								PaxType: item.PaxType,
								DateOfBirth: item.dob ? item.dob : '1998-03-09',
								Gender: 1,
								PassportNo: item.passportnumber ? item.passportnumber : '',
								PassportExpiry: item.expiry ? item.expiry : '',
								AddressLine1: 'Dhanbad',
								AddressLine2: '',
								Fare: {
									Currency: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.Currency
										: '',
									BaseFare: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.OfferedFare
										: '',
									Tax: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.Tax
										: '',
									YQTax: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.YQTax
										: '',
									AdditionalTxnFeePub: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.AdditionalTxnFeePub
										: '',
									AdditionalTxnFeeOfrd: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.AdditionalTxnFeeOfrd
										: '',
									OtherCharges: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.OtherCharges
										: '',
									Discount: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.Discount
										: '',
									PublishedFare: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.PublishedFare
										: '',
									OfferedFare: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.OfferedFare
										: '',
									TdsOnCommission: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.TdsOnCommission
										: '',
									TdsOnPLB: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.TdsOnPLB
										: '',
									TdsOnIncentive: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.TdsOnIncentive
										: '',
									ServiceFee: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.ServiceFee
										: '',
								},
								City: 'Dhanbad',
								CountryCode: 'IN',
								CellCountryCode: '+91',
								ContactNo: location.state.formData
									? location.state.formData.phone
									: '',
								Nationality: item.nationality ? item.nationality : 'IN',
								Email: location.state.formData
									? location.state.formData.email
									: '',
								IsLeadPax: true,
								FFAirlineCode: null,
								FFNumber: '',
								GSTCompanyAddress:
									'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
								GSTCompanyContactNumber: '8877909555',
								GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
								GSTNumber: '20AALCR5274P1ZV',
								GSTCompanyEmail: 'sales@musafirbazar.com',
							};
						}),
						Baggage: [],
						MealDynamic: [],
						SeatDynamic: [],
					};
					console.log('FormData', formDataLCC);
					dispatch(
						FlightTicketLCCDispatchReturn(
							formDataLCC,
							history,
							location.state,
							agentlogin
						)
					);
				} else if (location.state.selectedReturn[0]?.IsLCC === false) {
					let formData = {
						TraceId: location.state.IDTBO,
						ResultIndex: location.state.selectedReturn[0].ResultIndex,
						orderId: location.state.orderID,
						paymentId: payment === 'offline' ? invoice : invoice,
						Passengers: BookingFormData.map((item) => {
							return {
								Title: item.title,
								FirstName: item.firstname,
								LastName: item.lastname,
								PaxType: item.PaxType,
								DateOfBirth: item.dob ? item.dob : '1998-03-09',
								Gender: 1,
								PassportNo: item.passportnumber ? item.passportnumber : '',
								PassportExpiry: item.expiry ? item.expiry : '',
								AddressLine1: 'Dhanabad',
								AddressLine2: '',
								Fare: {
									Currency: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.Currency
										: '',
									BaseFare: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.OfferedFare
										: '',
									Tax: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.Tax
										: '',
									YQTax: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.YQTax
										: '',
									AdditionalTxnFeePub: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.AdditionalTxnFeePub
										: '',
									AdditionalTxnFeeOfrd: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.AdditionalTxnFeeOfrd
										: '',
									OtherCharges: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.OtherCharges
										: '',
									Discount: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.Discount
										: '',
									PublishedFare: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.PublishedFare
										: '',
									OfferedFare: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.OfferedFare
										: '',
									TdsOnCommission: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.TdsOnCommission
										: '',
									TdsOnPLB: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.TdsOnPLB
										: '',
									TdsOnIncentive: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.TdsOnIncentive
										: '',
									ServiceFee: location.state.selectedReturn[0]
										? location.state.selectedReturn[0].Fare.ServiceFee
										: '',
								},
								City: 'Dhanbad',
								CountryCode: 'IN',
								CellCountryCode: '+91',
								ContactNo: location.state.formData
									? location.state.formData.phone
									: '',
								Nationality: item.nationality ? item.nationality : 'IN',
								Email: location.state.formData
									? location.state.formData.email
									: '',
								IsLeadPax: true,
								FFAirlineCode: null,
								FFNumber: '',
								GSTCompanyAddress:
									'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
								GSTCompanyContactNumber: '8877909555',
								GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
								GSTNumber: '20AALCR5274P1ZV',
								GSTCompanyEmail: 'sales@musafirbazar.com',
							};
						}),
						Baggage: [],
						MealDynamic: [],
						SeatDynamic: [],
					};
					dispatch(
						FlightBookDispatch(
							formData,
							history,
							location.state.IDTBO,
							location
						)
					);
				}
			} else if (etrvotbor) {
				if (payment === 'offline') {
					let debit = {
						orderId: location.state.orderID,
						paymentId:
							payment === 'offline' ? invoice : response.razorpay_payment_id,
					};
					dispatch(flightDebitAmount(debit));
				}
				if (location.state.selectedonward[0]?.Airline_Code) {
					let formEtrav = {
						Customer_Mobile: location.state.formData.phone,
						Passenger_Mobile: location.state.formData.phone,
						orderId: location.state.orderID,
						paymentId:
							payment === 'offline' ? invoice : response.razorpay_payment_id,
						WhatsAPP_Mobile: null,
						Passenger_Email: location.state.formData
							? location.state.formData.email
							: '',
						PAX_Details: BookingFormData.map((item, index) => {
							return {
								Pax_Id: index + 1,
								Pax_type: item.PaxType - 1,
								Title: item.title,
								First_Name: item.firstname,
								Last_Name: item.lastname,
								Gender: 0,
								Age: null,
								DOB: item.dob ? item.dob : '1998-03-09',
								Passport_Number: item.passportnumber
									? item.passportnumber
									: null,
								Passport_Issuing_Country: null,
								Passport_Expiry: item.expiry ? item.expiry : null,
								Nationality: null,
								FrequentFlyerDetails: null,
							};
						}),

						GST: true,
						GST_Number: '',
						GST_HolderName: 'GST Holder Name',
						GST_Address: 'GST Address',
						BookingFlightDetails: fareQuoteEtrav.result.AirRepriceResponses.map(
							(item) => {
								return {
									Search_Key: location.state.IDEtrav,
									Flight_Key: item.Flight.Flight_Key,
									BookingSSRDetails: [],
								};
							}
						),
						CostCenterId: 0,
						ProjectId: 0,
						BookingRemark: `${location.state.returnBody.Segments[0].Origin}-${location.state.returnBody.Segments[0].Destination} ${location.state.returnBody.Segments[0].PreferredDepartureTime} ${invoice}`,
						CorporateStatus: 0,
						CorporatePaymentMode: 0,
						MissedSavingReason: null,
						CorpTripType: null,
						CorpTripSubType: null,
						TripRequestId: null,
						BookingAlertIds: null,
					};
					// console.log("first",formEtrav)
					dispatch(
						FlightBookEtravDispatch(
							formEtrav,
							history,
							payment,
							location.state,
							agentlogin
						)
					);
				}
				if (location.state.selectedReturn[0]?.AirlineCode) {
					if (location.state.selectedReturn[0].IsLCC) {
						console.log('LCC');
						let formDataLCC = {
							TraceId: location.state.IDTBO,
							ResultIndex: location.state.selectedReturn[0].ResultIndex,
							orderId: location.state.orderID,
							paymentId:
								payment === 'offline' ? invoice : response.razorpay_payment_id,
							Passengers: BookingFormData.map((item) => {
								return {
									Title: item.title,
									FirstName: item.firstname,
									LastName: item.lastname,
									PaxType: item.PaxType,
									DateOfBirth: item.dob ? item.dob : '1998-03-09',
									Gender: 1,
									PassportNo: item.passportnumber ? item.passportnumber : '',
									PassportExpiry: item.expiry ? item.expiry : '',
									AddressLine1: 'demoaddress',
									AddressLine2: '',
									Fare: {
										Currency: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.Currency
											: '',
										BaseFare: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.OfferedFare
											: '',
										Tax: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.Tax
											: '',
										YQTax: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.YQTax
											: '',
										AdditionalTxnFeePub: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare
													.AdditionalTxnFeePub
											: '',
										AdditionalTxnFeeOfrd: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare
													.AdditionalTxnFeeOfrd
											: '',
										OtherCharges: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.OtherCharges
											: '',
										Discount: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.Discount
											: '',
										PublishedFare: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.PublishedFare
											: '',
										OfferedFare: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.OfferedFare
											: '',
										TdsOnCommission: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.TdsOnCommission
											: '',
										TdsOnPLB: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.TdsOnPLB
											: '',
										TdsOnIncentive: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.TdsOnIncentive
											: '',
										ServiceFee: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.ServiceFee
											: '',
									},
									City: '',
									CountryCode: 'IN',
									CellCountryCode: '+91',
									ContactNo: location.state.formData
										? location.state.formData.phone
										: '',
									Nationality: 'IN',
									Email: location.state.formData
										? location.state.formData.email
										: '',
									IsLeadPax: true,
									FFAirlineCode: null,
									FFNumber: '',
									GSTCompanyAddress: '',
									GSTCompanyContactNumber: '',
									GSTCompanyName: '',
									GSTNumber: '',
									GSTCompanyEmail: '',
								};
							}),
							Baggage: [],
							MealDynamic: [],
							SeatDynamic: [],
						};
						console.log('FormData', formDataLCC);
						dispatch(
							FlightTicketLCCDispatch(
								formDataLCC,
								history,
								location.state,
								agentlogin
							)
						);
					} else if (location.state.selectedReturn[0].IsLCC === false) {
						console.log('ISlcc');
						let formData = {
							TraceId: location.state.IDTBO,
							ResultIndex: location.state.selectedReturn[0].ResultIndex,
							orderId: location.state.orderID,
							paymentId:
								response === 'success' ? invoice : response.razorpay_payment_id,
							Passengers: BookingFormData.map((item) => {
								return {
									Title: item.title,
									FirstName: item.firstname,
									LastName: item.lastname,
									PaxType: item.PaxType,
									DateOfBirth: item.dob ? item.dob : '1998-03-09',
									Gender: 1,
									PassportNo: item.passportnumber ? item.passportnumber : '',
									PassportExpiry: item.expiry ? item.expiry : '',
									AddressLine1: 'demoaddress',
									AddressLine2: '',
									Fare: {
										Currency: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.Currency
											: '',
										BaseFare: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.OfferedFare
											: '',
										Tax: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.Tax
											: '',
										YQTax: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.YQTax
											: '',
										AdditionalTxnFeePub: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare
													.AdditionalTxnFeePub
											: '',
										AdditionalTxnFeeOfrd: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare
													.AdditionalTxnFeeOfrd
											: '',
										OtherCharges: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.OtherCharges
											: '',
										Discount: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.Discount
											: '',
										PublishedFare: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.PublishedFare
											: '',
										OfferedFare: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.OfferedFare
											: '',
										TdsOnCommission: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.TdsOnCommission
											: '',
										TdsOnPLB: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.TdsOnPLB
											: '',
										TdsOnIncentive: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.TdsOnIncentive
											: '',
										ServiceFee: location.state.selectedReturn[0]
											? location.state.selectedReturn[0].Fare.ServiceFee
											: '',
									},
									City: 'demo',
									CountryCode: 'IN',
									CellCountryCode: '+91',
									ContactNo: location.state.formData
										? location.state.formData.phone
										: '',
									Nationality: 'IN',
									Email: location.state.formData
										? location.state.formData.email
										: '',
									IsLeadPax: true,
									FFAirlineCode: null,
									FFNumber: '',
									GSTCompanyAddress: '',
									GSTCompanyContactNumber: '',
									GSTCompanyName: '',
									GSTNumber: '',
									GSTCompanyEmail: '',
								};
							}),
							Baggage: [],
							MealDynamic: [],
							SeatDynamic: [],
						};
						dispatch(
							FlightBookDispatch(formData, history, location.state.IDTBO)
						);
					}
				}
			} else if (etravrtboo) {
				if (payment === 'offline') {
					let debit = {
						orderId: location.state.orderID,
						paymentId:
							payment === 'offline' ? invoice : response.razorpay_payment_id,
					};
					dispatch(flightDebitAmount(debit));
				}
				if (location.state.selectedReturn[0]?.Airline_Code) {
					let formEtrav = {
						Customer_Mobile: location.state.formData.phone,
						Passenger_Mobile: location.state.formData.phone,
						orderId: location.state.orderID,
						paymentId:
							payment === 'offline' ? invoice : response.razorpay_payment_id,
						WhatsAPP_Mobile: null,
						Passenger_Email: location.state.formData
							? location.state.formData.email
							: '',
						PAX_Details: BookingFormData.map((item, index) => {
							return {
								Pax_Id: index + 1,
								Pax_type: item.PaxType - 1,
								Title: item.title,
								First_Name: item.firstname,
								Last_Name: item.lastname,
								Gender: 0,
								Age: null,
								DOB: item.dob ? item.dob : '1998-03-09',
								Passport_Number: item.passportnumber
									? item.passportnumber
									: null,
								Passport_Issuing_Country: null,
								Passport_Expiry: item.expiry ? item.expiry : null,
								Nationality: null,
								FrequentFlyerDetails: null,
							};
						}),

						GST: true,
						GST_Number: '',
						GST_HolderName: 'GST Holder Name',
						GST_Address: 'GST Address',
						BookingFlightDetails: fareQuoteEtrav.result.AirRepriceResponses.map(
							(item) => {
								return {
									Search_Key: location.state.IDEtrav,
									Flight_Key: item.Flight.Flight_Key,
									BookingSSRDetails: [],
								};
							}
						),
						CostCenterId: 0,
						ProjectId: 0,
						BookingRemark: `${location.state.returnBody.Segments[0].Origin}-${location.state.returnBody.Segments[0].Destination} ${location.state.returnBody.Segments[0].PreferredDepartureTime} ${invoice}`,
						CorporateStatus: 0,
						CorporatePaymentMode: 0,
						MissedSavingReason: null,
						CorpTripType: null,
						CorpTripSubType: null,
						TripRequestId: null,
						BookingAlertIds: null,
					};
					// console.log("first",formEtrav)
					dispatch(
						FlightBookEtravDispatch(
							formEtrav,
							history,
							payment,
							location.state,
							agentlogin
						)
					);
				}
				if (location.state.selectedonward[0]?.AirlineCode) {
					if (location.state.selectedonward[0].IsLCC) {
						console.log('LCC');
						let formDataLCC = {
							TraceId: location.state.IDTBO,
							ResultIndex: location.state.selectedonward[0].ResultIndex,
							orderId: location.state.orderID,
							paymentId:
								payment === 'offline' ? invoice : response.razorpay_payment_id,
							Passengers: BookingFormData.map((item) => {
								return {
									Title: item.title,
									FirstName: item.firstname,
									LastName: item.lastname,
									PaxType: item.PaxType,
									DateOfBirth: item.dob ? item.dob : '1998-03-09',
									Gender: 1,
									PassportNo: item.passportnumber ? item.passportnumber : '',
									PassportExpiry: item.expiry ? item.expiry : '',
									AddressLine1: 'demoaddress',
									AddressLine2: '',
									Fare: {
										Currency: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.Currency
											: '',
										BaseFare: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.OfferedFare
											: '',
										Tax: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.Tax
											: '',
										YQTax: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.YQTax
											: '',
										AdditionalTxnFeePub: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare
													.AdditionalTxnFeePub
											: '',
										AdditionalTxnFeeOfrd: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare
													.AdditionalTxnFeeOfrd
											: '',
										OtherCharges: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.OtherCharges
											: '',
										Discount: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.Discount
											: '',
										PublishedFare: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.PublishedFare
											: '',
										OfferedFare: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.OfferedFare
											: '',
										TdsOnCommission: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.TdsOnCommission
											: '',
										TdsOnPLB: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.TdsOnPLB
											: '',
										TdsOnIncentive: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.TdsOnIncentive
											: '',
										ServiceFee: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.ServiceFee
											: '',
									},
									City: 'demo',
									CountryCode: 'IN',
									CellCountryCode: '+91',
									ContactNo: location.state.formData
										? location.state.formData.phone
										: '',
									Nationality: 'IN',
									Email: location.state.formData
										? location.state.formData.email
										: '',
									IsLeadPax: true,
									FFAirlineCode: null,
									FFNumber: '',
									GSTCompanyAddress: '',
									GSTCompanyContactNumber: '',
									GSTCompanyName: '',
									GSTNumber: '',
									GSTCompanyEmail: '',
								};
							}),
							Baggage: [],
							MealDynamic: [],
							SeatDynamic: [],
						};
						console.log('FormData', formDataLCC);
						dispatch(
							FlightTicketLCCDispatch(
								formDataLCC,
								history,
								location.state,
								agentlogin
							)
						);
					} else if (location.state.selectedonward[0]?.IsLCC === false) {
						let formData = {
							TraceId: location.state.IDTBO,
							ResultIndex: location.state.selectedonward[0].ResultIndex,
							orderId: location.state.orderID,
							paymentId:
								payment === 'offline' ? invoice : response.razorpay_payment_id,
							Passengers: BookingFormData.map((item) => {
								return {
									Title: item.title,
									FirstName: item.firstname,
									LastName: item.lastname,
									PaxType: item.PaxType,
									DateOfBirth: item.dob ? item.dob : '1998-03-09',
									Gender: 1,
									PassportNo: item.passportnumber ? item.passportnumber : '',
									PassportExpiry: item.expiry ? item.expiry : '',
									AddressLine1: 'demoaddress',
									AddressLine2: '',
									Fare: {
										Currency: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.Currency
											: '',
										BaseFare: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.OfferedFare
											: '',
										Tax: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.Tax
											: '',
										YQTax: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.YQTax
											: '',
										AdditionalTxnFeePub: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare
													.AdditionalTxnFeePub
											: '',
										AdditionalTxnFeeOfrd: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare
													.AdditionalTxnFeeOfrd
											: '',
										OtherCharges: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.OtherCharges
											: '',
										Discount: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.Discount
											: '',
										PublishedFare: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.PublishedFare
											: '',
										OfferedFare: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.OfferedFare
											: '',
										TdsOnCommission: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.TdsOnCommission
											: '',
										TdsOnPLB: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.TdsOnPLB
											: '',
										TdsOnIncentive: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.TdsOnIncentive
											: '',
										ServiceFee: location.state.selectedonward[0]
											? location.state.selectedonward[0].Fare.ServiceFee
											: '',
									},
									City: 'demo',
									CountryCode: 'IN',
									CellCountryCode: '+91',
									ContactNo: location.state.formData
										? location.state.formData.phone
										: '',
									Nationality: 'IN',
									Email: location.state.formData
										? location.state.formData.email
										: '',
									IsLeadPax: true,
									FFAirlineCode: null,
									FFNumber: '',
									GSTCompanyAddress: '',
									GSTCompanyContactNumber: '',
									GSTCompanyName: '',
									GSTNumber: '',
									GSTCompanyEmail: '',
								};
							}),
							Baggage: [],
							MealDynamic: [],
							SeatDynamic: [],
						};
						dispatch(
							FlightBookDispatch(formData, history, location.state.IDTBO)
						);
					}
				}
			} else if (
				location.state.selectedonward[0]?.Supplier === 'TJK' &&
				location.state.selectedReturn[0]?.Supplier === 'TJK'
			) {
				let formDataTJK = {
					bookingId: farequotetripjack.result.bookingId,
					paymentInfos: [
						{
							amount:
								farequotetripjack.result.totalPriceInfo.totalFareDetail.fC.TF,
						},
					],
					travellerInfo: BookingFormData.map((item, index) => {
						return {
							ti: item.title,
							fN: item.firstname,
							lN: item.lastname,
							pt:
								item.PaxType == 1
									? 'ADULT'
									: item.PaxType == 2
									? 'CHILD'
									: item.PaxType == 3
									? 'INFANT'
									: '',
							dob: item.dob ? item.dob : '1996-04-09',
							pNat: 'IN',
							pid: '2021-09-08',
							pNum: item.passportnumber ? item.passportnumber : null,
							eD: item.expiry ? item.expiry : null,
						};
					}),
					gstInfo: {
						gstNumber: '18AABCU9603R1ZM',
						email: 'apitest2ER@apitest.com ',
						registeredName: 'XYZ Pvt Ltd',
						mobile: '+918890005606',
						address: 'Dhanbad',
					},
					deliveryInfo: {
						emails: [location.state.formData.email],
						contacts: [location.state.formData.phone],
					},
				};
				dispatch(FlightBookTripJackDispatch(formDataTJK, history));
			}
		}
		if (location.state.intFlight) {
			console.log('first');
			if (location.state.intFlight?.IsLCC) {
				console.log('LCC');
				let formDataLCC = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.intFlight.ResultIndex,
					orderId: location.state.orderID,
					paymentId: payment === 'offline' ? invoice : invoice,
					Passengers: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Currency
									: '',
								BaseFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								Tax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Tax
									: '',
								YQTax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.YQTax
									: '',
								AdditionalTxnFeePub: fareQuote
									? fareQuote?.result?.Response.Results.Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: fareQuote
									? fareQuote?.result?.Response.Results.Fare
											.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OtherCharges
									: '',
								Discount: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Discount
									: '',
								PublishedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.PublishedFare
									: '',
								OfferedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								TdsOnCommission: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
									: '',
								TdsOnPLB: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
									: '',
								TdsOnIncentive: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
									: '',
								ServiceFee: fareQuote
									? fareQuote?.result?.Response.Results.Fare.ServiceFee
									: '',
							},
							City: '',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: item.nationality ? item.nationality : 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress:
								'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
							GSTCompanyContactNumber: '8877909555',
							GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
							GSTNumber: '20AALCR5274P1ZV',
							GSTCompanyEmail: 'sales@musafirbazar.com',
						};
					}),
					Baggage: [],
					MealDynamic: [],
					SeatDynamic: [],
				};
				console.log('FormData', formDataLCC);
				let debit = {
					orderId: location.state.orderID,
					paymentId:
						payment === 'offline' ? invoice : response.razorpay_payment_id,
				};
				dispatch(flightDebitAmount(debit));
				dispatch(
					FlightTicketLCCDispatch(
						formDataLCC,
						history,
						location.state,
						agentlogin
					)
				);
			} else if (location.state?.intFlight?.IsLCC === false) {
				let formData = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.intFlight.ResultIndex,
					orderId: location.state.orderID,
					paymentId: payment === 'offline' ? invoice : invoice,
					Passengers: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Currency
									: '',
								BaseFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								Tax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Tax
									: '',
								YQTax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.YQTax
									: '',
								AdditionalTxnFeePub: fareQuote
									? fareQuote?.result?.Response.Results.Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: fareQuote
									? fareQuote?.result?.Response.Results.Fare
											.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OtherCharges
									: '',
								Discount: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Discount
									: '',
								PublishedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.PublishedFare
									: '',
								OfferedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								TdsOnCommission: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
									: '',
								TdsOnPLB: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
									: '',
								TdsOnIncentive: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
									: '',
								ServiceFee: fareQuote
									? fareQuote?.result?.Response.Results.Fare.ServiceFee
									: '',
							},
							City: '',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: item.nationality ? item.nationality : 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress:
								'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
							GSTCompanyContactNumber: '8877909555',
							GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
							GSTNumber: '20AALCR5274P1ZV',
							GSTCompanyEmail: 'sales@musafirbazar.com',
						};
					}),
					Baggage: [],
					MealDynamic: [],
					SeatDynamic: [],
				};
				let debit = {
					orderId: location.state.orderID,
					paymentId:
						payment === 'offline' ? invoice : response.razorpay_payment_id,
				};
				dispatch(flightDebitAmount(debit));
				dispatch(FlightBookDispatch(formData, history));
			}
		}
	};
	const PGCharge = 200;
	const handleFormSubmit = (data) => {
		const selectedFlight = location.state.OneflightData;
		const selectedFlightINT = location.state.intFlight
			? location.state.intFlight
			: '';
		const priceDetails = fareQuoteEtrav
			? fareQuoteEtrav.result?.AirRepriceResponses[0].Flight
			: fareQuote
			? fareQuote.result?.Response.Results
			: farequotetripjack
			? farequotetripjack.result
			: '';
		const priceDetails2 = fareQuote ? fareQuote.result?.Response.Results : '';
		const priceDetails3 = fareQuoteReturn
			? fareQuoteReturn.result?.Response.Results
			: '';
		const TBOINTPA = location?.state?.intFlight
			? Number(
					Number(
						Math.round(
							fareQuote
								? fareQuote?.result?.Response?.Results.Fare.Total_Amount
								: location.state.intFlight?.Fare?.Total_Amount
						)
					) -
						Number(
							Math.round(
								fareQuote
									? fareQuote?.result.Response.Results.Fare.MFB_Discount
									: location.state.intFlight?.Fare?.MFB_Discount
							)
						) -
						Number(Math.round(location.state.intFlight?.Fare?.agentMarkup))
			  )
			: 0;
		const ETRPA =
			Number(
				Math.round(
					fareQuoteEtrav
						? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight.Fares
								.Total_Amount
						: location.state.OneflightData?.Fares?.Total_Amount
				)
			) - Number(Math.round(location.state.OneflightData?.Fares?.agentMarkup));
		const TBOPA = Number(
			Number(
				Math.round(
					fareQuote
						? fareQuote?.result?.Response?.Results.Fare.Total_Amount
						: location.state.OneflightData?.Fare?.Total_Amount
				)
			) -
				Number(
					Math.round(
						fareQuote
							? fareQuote?.result.Response.Results.Fare.MFB_Discount
							: location.state.OneflightData?.Fare?.MFB_Discount
					)
				) -
				Number(Math.round(location.state.OneflightData?.Fare?.agentMarkup)) +
				Number(
					Math.round(
						Number(
							location.state.formData.adults &&
								location.state.formData.adults?.length > 0 &&
								location.state.formData.adults
									.map((item) =>
										parseInt(item?.baggage?.Price ? item?.baggage?.Price : 0)
									)
									.reduce((prev, next) => prev + next)
						) +
							Number(
								location.state.formData.childs &&
									location.state.formData.childs?.length > 0 &&
									location.state.formData.childs
										.map((item) =>
											parseInt(item?.baggage?.Price ? item?.baggage?.Price : 0)
										)
										.reduce((prev, next) => prev + next)
							)
					)
				) +
				Number(
					Math.round(
						Number(
							location.state.formData.adults &&
								location.state.formData.adults?.length > 0 &&
								location.state.formData.adults
									.map((item) =>
										parseInt(item?.meal?.Price ? item?.meal?.Price : 0)
									)
									.reduce((prev, next) => prev + next)
						) +
							Number(
								location.state.formData.childs &&
									location.state.formData.childs?.length > 0 &&
									location.state.formData.childs
										.map((item) =>
											parseInt(item?.meal?.Price ? item?.meal?.Price : 0)
										)
										.reduce((prev, next) => prev + next)
							)
					)
				) +
				Number(
					Math.round(
						Number(
							location.state.formData.adults &&
								location.state.formData.adults?.length > 0 &&
								location.state.formData.adults
									.map((item) =>
										parseInt(item?.seat?.Price ? item?.seat?.Price : 0)
									)
									.reduce((prev, next) => prev + next)
						) +
							Number(
								location.state.formData.childs &&
									location.state.formData.childs?.length > 0 &&
									location.state.formData.childs
										.map((item) =>
											parseInt(item?.seat?.Price ? item?.seat?.Price : 0)
										)
										.reduce((prev, next) => prev + next)
							)
					)
				)
		);
		const TJKPA = Number(
			Number(
				Math.round(
					location.state.OneflightData?.totalPriceList?.fd.Total_Amount
				)
			) -
				Number(
					Math.round(
						location.state.OneflightData?.totalPriceList?.fd.agentMarkup
					)
				)
		);
		const TBOPARETURN = location.state.selectedonward
			? Number(
					Number(
						Math.round(
							fareQuote
								? fareQuote?.result?.Response?.Results.Fare.Total_Amount
								: location.state.selectedonward[0].Fare.Total_Amount
						)
					) -
						Number(
							Math.round(
								fareQuote
									? fareQuote?.result.Response.Results.Fare.MFB_Discount
									: location.state.selectedonward[0].Fare.MFB_Discount
							)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare?.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(
							fareQuoteReturn
								? fareQuoteReturn?.result?.Response?.Results.Fare.Total_Amount
								: location.state.selectedReturn[0].Fare.Total_Amount
						)
					) -
						Number(
							Math.round(
								fareQuoteReturn
									? fareQuoteReturn?.result.Response.Results.Fare.MFB_Discount
									: location.state.selectedReturn[0].Fare.MFB_Discount
							)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare?.agentMarkup)
						)
			  )
			: '';
		const payableAmout = location.state.OneflightData
			? location.state.OneflightData?.Supplier === 'ETR'
				? Number(ETRPA) + Number((Number(ETRPA) * 2.5) / 100)
				: location.state.OneflightData?.Supplier === 'TBO'
				? Number(TBOPA) + Number((Number(TBOPA) * 2.5) / 100)
				: location.state.OneflightData?.Supplier === 'TJK'
				? Number(TJKPA) + Number((Number(TJKPA) * 2.5) / 100)
				: ''
			: location.state.intFlight?.Supplier === 'TBO'
			? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
			  Number(Math.round(location.state.intFlight.Fare.agentMarkup)) -
			  Number(Math.round(location.state.intFlight.Fare.MFB_Discount)) +
			  Number(
					(Number(
						Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
							Number(Math.round(location.state.intFlight.Fare.agentMarkup)) -
							Number(Math.round(location.state.intFlight.Fare.MFB_Discount))
					) *
						2.5) /
						100
			  )
			: Number(TBOPARETURN) + Number((Number(TBOPARETURN) * 2.5) / 100);

		if (location.state.selectedonward) {
			addBookingReturn(
				location,
				agentlogin,
				payment,
				payableAmout,
				priceDetails2,
				priceDetails3,
				selectedFlight,
				dispatch,
				FlightAddBookingDispatch,
				PGCharge,
				BookingFormData,
				fareQuoteEtrav,
				fareQuote,
				fareQuoteReturn,
				handlePayment,
				TBOPARETURN
			);
		} else if (
			location.state.OneflightData?.Supplier === 'ETR' ||
			location.state.OneflightData?.Supplier === 'TBO' ||
			location.state.OneflightData?.Supplier === 'TJK'
		) {
			addBooking(
				location,
				agentlogin,
				payment,
				payableAmout,
				priceDetails,
				selectedFlight,
				dispatch,
				FlightAddBookingDispatch,
				BookingFormData,
				fareQuoteEtrav,
				fareQuote,
				farequotetripjack,
				handlePayment,
				ETRPA,
				TBOPA,
				TJKPA
			);
		} else if (location.state.intFlight) {
			console.log('Inflight');
			addINtBooking(
				location,
				agentlogin,
				payment,
				payableAmout,
				priceDetails,
				selectedFlightINT,
				dispatch,
				FlightAddBookingDispatch,
				PGCharge,
				BookingFormData,
				TBOINTPA,
				fareQuote
			);
		}

		if (payment === 'payworldline') {
			// dispatch(toggleEasebuzzModal(true));
			console.log('first');
			dispatch(setSelectedFlight(location.state));
			if (location.state.OneflightData?.Supplier === 'TJK') {
				let formDataTJK = {
					orderId: location.state.orderID,
					paymentId: payment === 'offline' ? invoice : invoice,
					bookingId: farequotetripjack.result.bookingId,
					paymentInfos: [
						{
							amount: Number(
								farequotetripjack.result.totalPriceInfo.totalFareDetail.fC.TF
							),
						},
					],
					travellerInfo: BookingFormData.map((item, index) => {
						return {
							ti: item.title,
							fN: item.firstname,
							lN: item.lastname,
							pt:
								item.PaxType == 1
									? 'ADULT'
									: item.PaxType == 2
									? 'CHILD'
									: item.PaxType == 3
									? 'INFANT'
									: '',
							dob: item.dob ? item.dob : '1996-04-09',
							pNat: item.nationality ? item.nationality : 'INDIA',
							pid: '2021-09-08',
							pNum: item.passportnumber ? item.passportnumber : null,
							eD: item.expiry ? item.expiry : null,
							ssrBaggageInfos: item.baggage
								? [
										{
											key: item.baggage?.amount,
											code: item.baggage?.code,
										},
								  ]
								: [],
							ssrMealInfos: item.meal
								? [
										{
											key: item.meal?.amount,
											code: item.meal?.code,
										},
								  ]
								: [],
						};
					}),
					gstInfo: {
						gstNumber: '',
						email: '',
						registeredName: '',
						mobile: '',
						address: '',
					},
					deliveryInfo: {
						emails: [location.state.formData.email],
						contacts: [location.state.formData.phone],
					},
				};

				dispatch(setBookingFormData(formDataTJK));
			} else if (location.state.OneflightData?.Supplier === 'TBO') {
				if (location.state.OneflightData?.IsLCC) {
					let formDataLCC = {
						TraceId: location.state.IDTBO,
						ResultIndex: location.state.OneflightData.ResultIndex,
						orderId: location.state.orderID,
						paymentId: payment === 'offline' ? invoice : invoice,
						Passengers: BookingFormData.map((item) => {
							return {
								Title: item.title,
								FirstName: item.firstname,
								LastName: item.lastname,
								PaxType: item.PaxType,
								DateOfBirth: item.dob ? item.dob : '1998-03-09',
								Gender: 1,
								PassportNo: item.passportnumber ? item.passportnumber : '',
								PassportExpiry: item.expiry ? item.expiry : '',
								AddressLine1: 'Dhanabad',
								AddressLine2: '',
								Fare: {
									Currency: fareQuote
										? fareQuote?.result?.Response.Results.Fare.Currency
										: '',
									BaseFare: fareQuote
										? fareQuote?.result?.Response.Results.Fare.OfferedFare
										: '',
									Tax: fareQuote
										? fareQuote?.result?.Response.Results.Fare.Tax
										: '',
									YQTax: fareQuote
										? fareQuote?.result?.Response.Results.Fare.YQTax
										: '',
									AdditionalTxnFeePub: fareQuote
										? fareQuote?.result?.Response.Results.Fare
												.AdditionalTxnFeePub
										: '',
									AdditionalTxnFeeOfrd: fareQuote
										? fareQuote?.result?.Response.Results.Fare
												.AdditionalTxnFeeOfrd
										: '',
									OtherCharges: fareQuote
										? fareQuote?.result?.Response.Results.Fare.OtherCharges
										: '',
									Discount: fareQuote
										? fareQuote?.result?.Response.Results.Fare.Discount
										: '',
									PublishedFare: fareQuote
										? fareQuote?.result?.Response.Results.Fare.PublishedFare
										: '',
									OfferedFare: fareQuote
										? fareQuote?.result?.Response.Results.Fare.OfferedFare
										: '',
									TdsOnCommission: fareQuote
										? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
										: '',
									TdsOnPLB: fareQuote
										? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
										: '',
									TdsOnIncentive: fareQuote
										? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
										: '',
									ServiceFee: fareQuote
										? fareQuote?.result?.Response.Results.Fare.ServiceFee
										: '',
								},
								City: 'Dhanbad',
								CountryCode: 'IN',
								CellCountryCode: '+91',
								ContactNo: location.state.formData
									? location.state.formData.phone
									: '',
								Nationality: item.nationality ? item.nationality : 'IN',
								Email: location.state.formData
									? location.state.formData.email
									: '',
								IsLeadPax: true,
								FFAirlineCode: null,
								FFNumber: '',
								GSTCompanyAddress: '',
								GSTCompanyContactNumber: '',
								GSTCompanyName: '',
								GSTNumber: '',
								GSTCompanyEmail: '',
								Baggage: item.baggage ? [item.baggage] : [],
								MealDynamic: item.meal ? [item.meal] : [],
							};
						}),

						// SeatDynamic: [],
					};
					dispatch(setBookingFormData(formDataLCC));
				} else if (location.state.OneflightData?.IsLCC === false) {
					let formData = {
						orderId: location.state.orderID,
						paymentId: payment === 'offline' ? invoice : invoice,
						TraceId: location.state.IDTBO,
						ResultIndex: location.state.OneflightData.ResultIndex,

						Passengers: BookingFormData.map((item) => {
							return {
								Title: item.title,
								FirstName: item.firstname,
								LastName: item.lastname,
								PaxType: item.PaxType,
								DateOfBirth: item.dob ? item.dob : '1998-03-09',
								Gender: 1,
								PassportNo: item.passportnumber ? item.passportnumber : '',
								PassportExpiry: item.expiry ? item.expiry : '',
								AddressLine1: 'Dhanbad',
								AddressLine2: '',
								Fare: {
									Currency: fareQuote
										? fareQuote?.result?.Response.Results.Fare.Currency
										: '',
									BaseFare: fareQuote
										? fareQuote?.result?.Response.Results.Fare.OfferedFare
										: '',
									Tax: fareQuote
										? fareQuote?.result?.Response.Results.Fare.Tax
										: '',
									YQTax: fareQuote
										? fareQuote?.result?.Response.Results.Fare.YQTax
										: '',
									AdditionalTxnFeePub: fareQuote
										? fareQuote?.result?.Response.Results.Fare
												.AdditionalTxnFeePub
										: '',
									AdditionalTxnFeeOfrd: fareQuote
										? fareQuote?.result?.Response.Results.Fare
												.AdditionalTxnFeeOfrd
										: '',
									OtherCharges: fareQuote
										? fareQuote?.result?.Response.Results.Fare.OtherCharges
										: '',
									Discount: fareQuote
										? fareQuote?.result?.Response.Results.Fare.Discount
										: '',
									PublishedFare: fareQuote
										? fareQuote?.result?.Response.Results.Fare.PublishedFare
										: '',
									OfferedFare: fareQuote
										? fareQuote?.result?.Response.Results.Fare.OfferedFare
										: '',
									TdsOnCommission: fareQuote
										? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
										: '',
									TdsOnPLB: fareQuote
										? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
										: '',
									TdsOnIncentive: fareQuote
										? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
										: '',
									ServiceFee: fareQuote
										? fareQuote?.result?.Response.Results.Fare.ServiceFee
										: '',
								},
								City: 'Dhanbad',
								CountryCode: 'IN',
								CellCountryCode: '+91',
								ContactNo: location.state.formData
									? location.state.formData.phone
									: '',
								Nationality: item.nationality ? item.nationality : 'IN',
								Email: location.state.formData
									? location.state.formData.email
									: '',
								IsLeadPax: true,
								FFAirlineCode: null,
								FFNumber: '',
								GSTCompanyAddress: '',
								GSTCompanyContactNumber: '',
								GSTCompanyName: '',
								GSTNumber: '',
								GSTCompanyEmail: '',
							};
						}),
						Baggage: [],
						MealDynamic: [],
						SeatDynamic: [],
					};
					dispatch(setBookingFormData(formData));
				}
			} else if (location.state.OneflightData?.Supplier === 'ETR') {
				let formEtrav = {
					Customer_Mobile: location.state.formData.phone,
					Passenger_Mobile: location.state.formData.phone,
					orderId: location.state.orderID,
					paymentId: payment === 'offline' ? invoice : invoice,
					WhatsAPP_Mobile: null,
					Passenger_Email: location.state.formData
						? location.state.formData.email
						: '',
					PAX_Details: BookingFormData.map((item, index) => {
						return {
							Pax_Id: index + 1,
							Pax_type: item.PaxType - 1,
							Title: item.title,
							First_Name: item.firstname,
							Last_Name: item.lastname,
							Gender: 1,
							Age: null,
							DOB: item.dob ? item.dob : '1998-03-09',
							Passport_Number: item.passportnumber ? item.passportnumber : null,
							Passport_Issuing_Country: item.passportIssuingCountry
								? item.passportIssuingCountry
								: 'INDIA',
							Passport_Expiry: item.expiry ? item.expiry : null,
							Nationality: item.nationality ? item.nationality : 'IN',
							FrequentFlyerDetails: null,
						};
					}),

					GST: true,
					GST_Number: '',
					GST_HolderName: 'GST Holder Name',
					GST_Address: 'GST Address',
					BookingFlightDetails: [
						{
							Search_Key: location.state.IDEtrav,
							Flight_Key:
								fareQuoteEtrav.result.AirRepriceResponses[0].Flight.Flight_Key,
							BookingSSRDetails: [],
						},
					],
					CostCenterId: 0,
					ProjectId: 0,
					BookingRemark: `${location.state.body.Segments[0].Origin}-${location.state.body.Segments[0].Destination} ${location.state.body.Segments[0].PreferredDepartureTime} ${invoice}`,
					CorporateStatus: 0,
					CorporatePaymentMode: 0,
					MissedSavingReason: null,
					CorpTripType: null,
					CorpTripSubType: null,
					TripRequestId: null,
					BookingAlertIds: null,
				};
				dispatch(setBookingFormData(formEtrav));
			} else if (location.state?.intFlight?.IsLCC) {
				let formDataLCC = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.intFlight.ResultIndex,
					orderId: location.state.orderID,
					paymentId: payment === 'offline' ? invoice : invoice,
					Passengers: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Currency
									: '',
								BaseFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								Tax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Tax
									: '',
								YQTax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.YQTax
									: '',
								AdditionalTxnFeePub: fareQuote
									? fareQuote?.result?.Response.Results.Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: fareQuote
									? fareQuote?.result?.Response.Results.Fare
											.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OtherCharges
									: '',
								Discount: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Discount
									: '',
								PublishedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.PublishedFare
									: '',
								OfferedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								TdsOnCommission: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
									: '',
								TdsOnPLB: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
									: '',
								TdsOnIncentive: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
									: '',
								ServiceFee: fareQuote
									? fareQuote?.result?.Response.Results.Fare.ServiceFee
									: '',
							},
							City: '',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: item.nationality ? item.nationality : 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress: '',
							GSTCompanyContactNumber: '',
							GSTCompanyName: '',
							GSTNumber: '',
							GSTCompanyEmail: '',
						};
					}),
					Baggage: [],
					MealDynamic: [],
					SeatDynamic: [],
				};
				dispatch(setBookingFormData(formDataLCC));
			} else if (location.state?.intFlight?.IsLCC === false) {
				let formData = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.intFlight.ResultIndex,
					orderId: location.state.orderID,
					paymentId: payment === 'offline' ? invoice : invoice,
					Passengers: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanabad',
							AddressLine2: '',
							Fare: {
								Currency: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Currency
									: '',
								BaseFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								Tax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Tax
									: '',
								YQTax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.YQTax
									: '',
								AdditionalTxnFeePub: fareQuote
									? fareQuote?.result?.Response.Results.Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: fareQuote
									? fareQuote?.result?.Response.Results.Fare
											.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OtherCharges
									: '',
								Discount: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Discount
									: '',
								PublishedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.PublishedFare
									: '',
								OfferedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								TdsOnCommission: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
									: '',
								TdsOnPLB: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
									: '',
								TdsOnIncentive: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
									: '',
								ServiceFee: fareQuote
									? fareQuote?.result?.Response.Results.Fare.ServiceFee
									: '',
							},
							City: '',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: item.nationality ? item.nationality : 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress: '',
							GSTCompanyContactNumber: '',
							GSTCompanyName: '',
							GSTNumber: '',
							GSTCompanyEmail: '',
						};
					}),
					Baggage: [],
					MealDynamic: [],
					SeatDynamic: [],
				};
				dispatch(setBookingFormData(formData));
			} else if (location.state.selectedReturn) {
				let formDataselectedonward = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.selectedonward[0].ResultIndex,
					orderId: location.state.orderID,
					paymentId: invoice,
					Passengers: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.Currency
									: '',
								BaseFare: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.OfferedFare
									: '',
								Tax: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.Tax
									: '',
								YQTax: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.YQTax
									: '',
								AdditionalTxnFeePub: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.OtherCharges
									: '',
								Discount: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.Discount
									: '',
								PublishedFare: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.PublishedFare
									: '',
								OfferedFare: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.OfferedFare
									: '',
								TdsOnCommission: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.TdsOnCommission
									: '',
								TdsOnPLB: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.TdsOnPLB
									: '',
								TdsOnIncentive: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.TdsOnIncentive
									: '',
								ServiceFee: location.state.selectedonward[0]
									? location.state.selectedonward[0].Fare.ServiceFee
									: '',
							},
							City: 'Dhanbad',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: item.nationality ? item.nationality : 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress: '',
							GSTCompanyContactNumber: '',
							GSTCompanyName: '',
							GSTNumber: '',
							GSTCompanyEmail: '',
						};
					}),
					Baggage: [],
					MealDynamic: [],
					SeatDynamic: [],
				};
				let formDataselectedReturn = {
					TraceId: location.state.IDTBO,
					ResultIndex: location.state.selectedReturn[0].ResultIndex,
					orderId: location.state.orderID,
					paymentId: invoice,
					Passengers: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.Currency
									: '',
								BaseFare: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.OfferedFare
									: '',
								Tax: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.Tax
									: '',
								YQTax: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.YQTax
									: '',
								AdditionalTxnFeePub: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.OtherCharges
									: '',
								Discount: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.Discount
									: '',
								PublishedFare: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.PublishedFare
									: '',
								OfferedFare: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.OfferedFare
									: '',
								TdsOnCommission: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.TdsOnCommission
									: '',
								TdsOnPLB: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.TdsOnPLB
									: '',
								TdsOnIncentive: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.TdsOnIncentive
									: '',
								ServiceFee: location.state.selectedReturn[0]
									? location.state.selectedReturn[0].Fare.ServiceFee
									: '',
							},
							City: 'Dhanbad',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: item.nationality ? item.nationality : 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress: '',
							GSTCompanyContactNumber: '',
							GSTCompanyName: '',
							GSTNumber: '',
							GSTCompanyEmail: '',
						};
					}),
					Baggage: [],
					MealDynamic: [],
					SeatDynamic: [],
				};
				dispatch(setBookingFormData(formDataselectedonward));
				dispatch(setBookingFormDataReturn(formDataselectedReturn));
			}
		}
	};
	const handleHold = (e) => {
		if (e.target.value === 'payworldline') {
			dispatch(setPayment(e.target.value));
			if (payworldline) {
				setPay(false);
				setOffline(false);
				setPayWorldline(false);
			} else {
				setPayWorldline(true);
				setPay(false);
				setOffline(false);
			}
		} else if (e.target.value === 'pay') {
			dispatch(setPayment(e.target.value));
			if (pay) {
				setPay(false);
				setOffline(false);
				setPayWorldline(false);
			} else {
				setPay(true);
				setPayWorldline(false);
				setOffline(false);
			}
		} else if (e.target.value === 'offline') {
			dispatch(setPayment(e.target.value));
			if (offline) {
				setPay(false);
				setPayWorldline(false);
				setOffline(false);
			} else {
				setPay(false);
				setPayWorldline(false);
				setOffline(true);
			}
		}

		console.log(e.target.value);
	};
	const adult = location.state.body
		? location.state.body.AdultCount
		: location.state.returnBody.AdultCount;
	const child = location.state.body
		? location.state.body.ChildCount
		: location.state.returnBody.ChildCount;
	const infant = location.state.body
		? location.state.body.InfantCount
		: location.state.returnBody.InfantCount;
	const ar = [];
	const ar2 = [];
	const ar3 = [];
	for (let i = 1; i <= adult; i++) {
		ar.push({});
	}
	for (let i = 1; i <= child; i++) {
		ar2.push({});
	}
	for (let i = 1; i <= infant; i++) {
		ar3.push({});
	}
	return (
		<>
			<Formik
				initialValues={{
					adults: location.state.formData.adults,
					childs: location.state.formData.childs,
					infants: location.state.formData.infants,
				}}
				onSubmit={handleFormSubmit}>
				{(formik) => (
					<Form>
						<Box
							className={classes.root}
							style={{
								background: 'white',
								padding: '10px 15px',
								border: '1px solid #e6e6e6',
								marginTop: '10px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20, alignItems: 'baseline' }}>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='email'
										type='email'
										disabled
										variant='outlined'
										value={location.state.formData.email}
										// error={errors.email}
										// label='Email ID'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='phone'
										disabled
										type='number'
										value={location.state.formData.phone}
										variant='outlined'
										// error={errors.phone}
										// label='Mobile No.'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
							</Grid>
						</Box>
						<FieldArray
							name='adult'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.adults.map((adult, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Adult.${index + 1}`}</div>

													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={4}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																disabled
																name={`adult.${index}.title`}
																type='text'
																variant='outlined'
																label='Select Title'
																value={adult.title}
																// error={errors.titleA1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{title.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={4}>
															<Field
																component={TextField}
																name={`adult.${index}.firstname`}
																type='text'
																disabled
																// error={errors.first_nameA1}
																variant='outlined'
																value={adult.firstname}
																// label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={4}>
															<Field
																component={TextField}
																name={`adult.${index}.lastname`}
																type='text'
																disabled
																value={adult.lastname}
																// error={errors.last_nameA1}
																variant='outlined'
																// label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adult.${index}.passportnumber`}
																		type='text'
																		disabled
																		value={adult.passportnumber}
																		// error={errors.last_nameA1}
																		variant='outlined'
																		// label="Enter Passport Number"
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adult.${index}.expiry`}
																		type='date'
																		disabled
																		// error={errors.last_nameA1}
																		variant='outlined'
																		value={adult.expiry}
																		// label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`adult.${index}.dob`}
																		type='date'
																		disabled
																		value={adult.dob}
																		// error={errors.last_nameA1}
																		variant='outlined'
																		// label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`adult.${index}.nationality`}
																		type='text'
																		disabled
																		variant='outlined'
																		label='Select Nationality'
																		value={adult.nationality}
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{countryName.map((item, index) => (
																			<MenuItem key={index} value={item.code}>
																				{item.name}
																			</MenuItem>
																		))}
																	</Field>
																	{/* <ErrorMessage name={`adult.${index}.title`} /> */}
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`adult.${index}.passportIssuingCountry`}
																		type='text'
																		disabled
																		variant='outlined'
																		value={adult.passportIssuingCountry}
																		label='Select Passport Issuing Country'
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{countryName.map((item, index) => (
																			<MenuItem key={index} value={item.name}>
																				{item.name}
																			</MenuItem>
																		))}
																	</Field>
																	{/* <ErrorMessage name={`adult.${index}.title`} /> */}
																</Grid>
															</>
														)}{' '}
														{location.state.OneflightData?.Supplier === 'TBO' &&
															location.state.OneflightData.IsLCC &&
															location.state.formData?.adults.length > 0 && (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Box
																		display='flex'
																		justifyContent='space-between'
																		alignItems='center'>
																		<span
																			style={{
																				width: '100%',
																				fontFamily: 'sans-serif',
																				color: 'grey',
																			}}>
																			Seat Preference :-
																			{location.state.formData?.adults?.map(
																				(i, ind) => {
																					return ind === index
																						? `${
																								i?.seat?.Origin
																									? i?.seat?.Origin
																									: ''
																						  } - ${
																								i?.seat?.Destination
																									? i?.seat?.Destination
																									: ''
																						  } : ${
																								i?.seat?.Code
																									? i?.seat?.Code
																									: ''
																						  }, (₹${
																								i?.seat?.Price
																									? i?.seat?.Price
																									: ''
																						  })`
																						: '';
																				}
																			)}
																		</span>
																	</Box>
																</Grid>
															)}
														{location.state.OneflightData?.Supplier === 'TBO' &&
															location.state.OneflightData.IsLCC &&
															location.state.formData?.adults.length > 0 && (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Box
																		display='flex'
																		justifyContent='space-between'
																		alignItems='center'>
																		<span
																			style={{
																				width: '100%',
																				fontFamily: 'sans-serif',
																				color: 'grey',
																			}}>
																			Meal Preference :-
																			{location.state.formData?.adults?.map(
																				(i, ind) => {
																					return ind === index
																						? `${
																								i?.meal?.Origin
																									? i?.meal?.Origin
																									: ''
																						  } - ${
																								i?.meal?.Destination
																									? i?.meal?.Destination
																									: ''
																						  } : ${
																								i?.meal?.AirlineDescription
																									? i?.meal?.AirlineDescription
																									: ''
																						  }, (₹${
																								i?.meal?.Price
																									? i?.meal?.Price
																									: ''
																						  })`
																						: '';
																				}
																			)}
																		</span>
																	</Box>
																</Grid>
															)}
														{location.state.OneflightData?.Supplier === 'TBO' &&
															location.state.OneflightData.IsLCC &&
															location.state.formData?.adults.length > 0 && (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Box
																		display='flex'
																		justifyContent='space-between'
																		alignItems='center'>
																		<span
																			style={{
																				width: '100%',
																				fontFamily: 'sans-serif',
																				color: 'grey',
																			}}>
																			Baggage Preference :-
																			{location.state.formData?.adults?.map(
																				(i, ind) => {
																					return ind === index
																						? `${
																								i?.baggage?.Origin
																									? i?.baggage?.Origin
																									: ''
																						  } - ${
																								i?.baggage?.Destination
																									? i?.baggage?.Destination
																									: ''
																						  } : ${
																								i?.baggage?.Weight
																									? i?.baggage?.Weight
																									: ''
																						  },kg  (₹${
																								i?.baggage?.Price
																									? i?.baggage?.Price
																									: ''
																						  })`
																						: '';
																				}
																			)}
																		</span>
																	</Box>
																</Grid>
															)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						<FieldArray
							name='child'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.childs?.map((child, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Child.${index + 1}`}</div>
													{/* <Field
														disabled
														type='text'
														name={`chil.${index}.firsName`}
														value={chil.firsName}
													/>
													<Field type='date' name={`chil.${index}.date`} /> */}
													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={3}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																name={`child.${index}.title`}
																type='text'
																value={child.title}
																variant='outlined'
																disabled
																label='Select Title'
																// error={errors.titleC1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{titleC.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`child.${index}.firstname`}
																type='text'
																value={child.firstname}
																variant='outlined'
																disabled
																// error={errors.first_nameC1}
																// label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`child.${index}.lastname`}
																type='text'
																value={child.lastname}
																variant='outlined'
																disabled
																// error={errors.last_nameC1}
																// label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`child.${index}.dob`}
																type='date'
																value={child.dob}
																variant='outlined'
																disabled
																// error={errors.last_nameC1}
																// label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`child.${index}.passportnumber`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		disabled
																		value={child.passportnumber}
																		// label="Enter Passport Number"
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`child.${index}.expiry`}
																		type='date'
																		disabled
																		value={child.expiry}
																		// error={errors.last_nameA1}
																		variant='outlined'
																		// label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`child.${index}.nationality`}
																		type='text'
																		disabled
																		variant='outlined'
																		label='Select Nationality'
																		value={child.nationality}
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{countryName.map((item, index) => (
																			<MenuItem key={index} value={item.code}>
																				{item.name}
																			</MenuItem>
																		))}
																	</Field>
																	{/* <ErrorMessage name={`adult.${index}.title`} /> */}
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`child.${index}.passportIssuingCountry`}
																		type='text'
																		variant='outlined'
																		disabled
																		value={child.passportIssuingCountry}
																		label='Select Passport Issuing Country'
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{countryName.map((item, index) => (
																			<MenuItem key={index} value={item.name}>
																				{item.name}
																			</MenuItem>
																		))}
																	</Field>
																	{/* <ErrorMessage name={`adult.${index}.title`} /> */}
																</Grid>
															</>
														)}
														{location.state.OneflightData?.Supplier === 'TBO' &&
															location.state.OneflightData.IsLCC &&
															location.state.formData?.childs.length > 0 && (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Box
																		display='flex'
																		justifyContent='space-between'
																		alignItems='center'>
																		<span
																			style={{
																				width: '100%',
																				fontFamily: 'sans-serif',
																				color: 'grey',
																			}}>
																			Seat Preference :-
																			{location.state.formData?.childs?.map(
																				(i, ind) => {
																					return ind === index
																						? `${
																								i?.seat?.Origin
																									? i?.seat?.Origin
																									: ''
																						  } - ${
																								i?.seat?.Destination
																									? i?.seat?.Destination
																									: ''
																						  } : ${
																								i?.seat?.Code
																									? i?.seat?.Code
																									: ''
																						  }, (₹${
																								i?.seat?.Price
																									? i?.seat?.Price
																									: ''
																						  })`
																						: '';
																				}
																			)}
																		</span>
																	</Box>
																</Grid>
															)}
														{location.state.OneflightData?.Supplier === 'TBO' &&
															location.state.OneflightData.IsLCC &&
															location.state.formData?.childs.length > 0 && (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Box
																		display='flex'
																		justifyContent='space-between'
																		alignItems='center'>
																		<span
																			style={{
																				width: '100%',
																				fontFamily: 'sans-serif',
																				color: 'grey',
																			}}>
																			Meal Preference :-
																			{location.state.formData?.childs?.map(
																				(i, ind) => {
																					return ind === index
																						? `${
																								i?.meal?.Origin
																									? i?.seat?.Origin
																									: ''
																						  } - ${
																								i?.meal?.Destination
																									? i?.seat?.Destination
																									: ''
																						  } : ${
																								i?.meal?.AirlineDescription
																									? i?.seat?.AirlineDescription
																									: ''
																						  }, (₹${
																								i?.meal?.Price
																									? i?.seat?.Price
																									: ''
																						  })`
																						: '';
																				}
																			)}
																		</span>
																	</Box>
																</Grid>
															)}

														{location.state.OneflightData?.Supplier === 'TBO' &&
															location.state.OneflightData.IsLCC &&
															location.state.formData?.childs.length > 0 && (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Box
																		display='flex'
																		justifyContent='space-between'
																		alignItems='center'>
																		<span
																			style={{
																				width: '100%',
																				fontFamily: 'sans-serif',
																				color: 'grey',
																			}}>
																			Baggage Preference :-
																			{location.state.formData?.childs?.map(
																				(i, ind) => {
																					return ind === index
																						? `${
																								i?.baggage?.Origin
																									? i?.baggage?.Origin
																									: ''
																						  } - ${
																								i?.baggage?.Destination
																									? i?.baggage?.Destination
																									: ''
																						  } : ${
																								i?.baggage?.Weight
																									? i?.baggage?.Weight
																									: ''
																						  },kg  (₹${
																								i?.baggage?.Price
																									? i?.baggage?.Price
																									: ''
																						  })`
																						: '';
																				}
																			)}
																		</span>
																	</Box>
																</Grid>
															)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						<FieldArray
							name='infant'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.infants?.map((infant, index) => (
											<>
												<Box
													className={classes.root}
													style={{
														background: 'white',
														padding: '10px 15px',
														border: '1px solid #e6e6e6',
														marginTop: '10px',
														boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
														borderRadius: '5px',
													}}>
													<div>{`Enter the Detail of Infant.${index + 1}`}</div>
													{/* <Field
														disabled
														type='text'
														name={`chil.${index}.firsName`}
														value={chil.firsName}
													/>
													<Field type='date' name={`chil.${index}.date`} /> */}
													<Grid
														container
														spacing={2}
														alignItems='center'
														style={{ marginTop: 20, alignItems: 'baseline' }}>
														<Grid
															item
															xs={12}
															sm={6}
															lg={3}
															className={classes.root2}
															style={{ alignItems: 'center' }}>
															<Field
																component={TextField}
																select
																name={`infant.${index}.title`}
																type='text'
																value={infant.title}
																variant='outlined'
																disabled
																label='Select Title'
																// error={errors.titleC1}
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	className: classes.inputPropsStyle,
																}}>
																{titleI.map((item, index) => (
																	<MenuItem key={index} value={item}>
																		{item}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infant.${index}.firstname`}
																type='text'
																value={infant.firstname}
																variant='outlined'
																disabled
																// error={errors.first_nameC1}
																// label='Enter First Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infant.${index}.lastname`}
																type='text'
																value={infant.lastname}
																variant='outlined'
																disabled
																// error={errors.last_nameC1}
																// label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														<Grid item xs={12} sm={6} lg={3}>
															<Field
																component={TextField}
																name={`infant.${index}.dob`}
																type='date'
																value={infant.dob}
																variant='outlined'
																disabled
																// error={errors.last_nameC1}
																// label='Enter Last Name'
																className={classes.selectField}
																InputProps={{
																	className: classes.InputPropsStyle,
																}}
																inputProps={{
																	style: { padding: 12 },
																}}
															/>
														</Grid>
														{selectedOrigin.country_code === 'IN' &&
														selectedDepart.country_code === 'IN' ? (
															''
														) : (
															<>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`infant.${index}.passportnumber`}
																		type='text'
																		// error={errors.last_nameA1}
																		variant='outlined'
																		disabled
																		value={infant.passportnumber}
																		// label="Enter Passport Number"
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid item xs={12} sm={6} lg={4}>
																	<Field
																		component={TextField}
																		name={`infant.${index}.expiry`}
																		type='date'
																		value={infant.expiry}
																		disabled
																		// error={errors.last_nameA1}
																		variant='outlined'
																		// label='Enter Passport Number'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`infant.${index}.nationality`}
																		type='text'
																		disabled
																		variant='outlined'
																		label='Select Nationality'
																		value={infant.nationality}
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{countryName.map((item, index) => (
																			<MenuItem key={index} value={item.code}>
																				{item.name}
																			</MenuItem>
																		))}
																	</Field>
																	{/* <ErrorMessage name={`adult.${index}.title`} /> */}
																</Grid>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={4}
																	className={classes.root2}
																	style={{ alignItems: 'center' }}>
																	<Field
																		component={TextField}
																		select
																		name={`infant.${index}.passportIssuingCountry`}
																		type='text'
																		disabled
																		variant='outlined'
																		value={infant.passportIssuingCountry}
																		label='Select Passport Issuing Country'
																		// error={errors.titleA1}
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			className: classes.inputPropsStyle,
																		}}>
																		{countryName.map((item, index) => (
																			<MenuItem key={index} value={item.name}>
																				{item.name}
																			</MenuItem>
																		))}
																	</Field>
																	{/* <ErrorMessage name={`adult.${index}.title`} /> */}
																</Grid>
															</>
														)}
													</Grid>
												</Box>
											</>
										))}
									</div>
								);
							}}
						/>
						{/* payment method */}
						<Box
							alignItems='center'
							display='flex'
							style={{
								background: 'white',
								padding: '10px 15px',
								border: '1px solid #e6e6e6',
								marginTop: '10px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							{agentlogin &&
							agentlogin.result &&
							agentlogin.result.result &&
							agentlogin.result.result.role === 'Agent' ? (
								// <></>
								<>
									{location.state.OneflightData ? (
										// OnewayPay with Offline
										<>
											{' '}
											<FormControlLabel
												style={{ marginRight: 0 }}
												value='offline'
												control={
													<Radio
														checked={offline}
														onClick={handleHold}
														disabled={
															location.state.OneflightData.AirlineCode
																? Number(
																		Math.round(
																			fareQuote
																				? fareQuote?.result?.Response?.Results
																						.Fare.Total_Amount
																				: location.state.OneflightData.Fare
																						.Total_Amount
																		)
																  ) -
																		Number(
																			Math.round(
																				fareQuote
																					? fareQuote?.result?.Response?.Results
																							.Fare.MFB_Discount
																					: location.state.OneflightData.Fare
																							.MFB_Discount
																			)
																		) <=
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: location.state.OneflightData.Airline_Code
																? Number(
																		Math.round(
																			fareQuoteEtrav
																				? fareQuoteEtrav?.result
																						?.AirRepriceResponses[0].Flight
																						.Fares.Total_Amount
																				: location.state.OneflightData.Fares
																						.Total_Amount
																		)
																  ) +
																		Number(
																			Math.round(
																				location.state.OneflightData
																					.Supplier === 'ETR'
																					? location.state.OneflightData.Fares
																							.Warning === null ||
																					  location.state.OneflightData.Fares
																							.Warning === ''
																						? 0
																						: location.state.OneflightData.Fares
																								.fdMarkup
																					: 0
																			)
																		) <=
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: location.state.OneflightData.Supplier ===
																  'TJK'
																? Number(
																		Math.round(
																			farequotetripjack
																				? farequotetripjack?.result
																						?.totalPriceInfo?.totalFareDetail
																						?.Total_Amount
																				: location.state.OneflightData
																						?.totalPriceList?.fd?.Total_Amount
																		)
																  ) <=
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: ''
														}
													/>
												}
											/>
											<span
												style={{
													opacity: location.state.OneflightData.AirlineCode
														? Number(
																Math.round(
																	fareQuote
																		? fareQuote?.result?.Response?.Results.Fare
																				.Total_Amount
																		: location.state.OneflightData.Fare
																				.Total_Amount
																)
														  ) -
																Number(
																	Math.round(
																		fareQuote
																			? fareQuote?.result?.Response?.Results
																					.Fare.MFB_Discount
																			: location.state.OneflightData.Fare
																					.MFB_Discount
																	)
																) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: location.state.OneflightData.Airline_Code
														? Number(
																Math.round(
																	fareQuoteEtrav
																		? fareQuoteEtrav?.result
																				?.AirRepriceResponses[0].Flight.Fares
																				.Total_Amount
																		: location.state.OneflightData.Fares
																				.Total_Amount
																)
														  ) +
																Number(
																	Math.round(
																		location.state.OneflightData.Supplier ===
																			'ETR'
																			? location.state.OneflightData.Fares
																					.Warning === null ||
																			  location.state.OneflightData.Fares
																					.Warning === ''
																				? 0
																				: location.state.OneflightData.Fares
																						.fdMarkup
																			: 0
																	)
																) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: location.state.OneflightData.Supplier === 'TJK'
														? Number(
																Math.round(
																	farequotetripjack
																		? farequotetripjack?.result?.totalPriceInfo
																				?.totalFareDetail?.Total_Amount
																		: location.state?.OneflightData
																				?.totalPriceList?.fd?.Total_Amount
																)
														  ) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: '',
												}}>
												Offline Amount
											</span>
											<span
												style={{
													marginLeft: 5,
													color: 'blue',
													opacity: location.state.OneflightData.AirlineCode
														? Number(
																Math.round(
																	fareQuote
																		? fareQuote?.result?.Response?.Results.Fare
																				.Total_Amount
																		: location.state.OneflightData.Fare
																				.Total_Amount
																)
														  ) -
																Number(
																	Math.round(
																		fareQuote
																			? fareQuote?.result?.Response?.Results
																					.Fare.MFB_Discount
																			: location.state.OneflightData.Fare
																					.MFB_Discount
																	)
																) +
																Number(
																	Math.round(
																		Number(
																			location.state.formData.adults &&
																				location.state.formData.adults?.length >
																					0 &&
																				location.state.formData.adults
																					.map((item) =>
																						parseInt(
																							item?.baggage?.Price
																								? item?.baggage?.Price
																								: 0
																						)
																					)
																					.reduce((prev, next) => prev + next)
																		) +
																			Number(
																				location.state.formData.childs &&
																					location.state.formData.childs
																						?.length > 0 &&
																					location.state.formData.childs
																						.map((item) =>
																							parseInt(
																								item?.baggage?.Price
																									? item?.baggage?.Price
																									: 0
																							)
																						)
																						.reduce((prev, next) => prev + next)
																			)
																	)
																) +
																Number(
																	Math.round(
																		Number(
																			location.state.formData.adults &&
																				location.state.formData.adults?.length >
																					0 &&
																				location.state.formData.adults
																					.map((item) =>
																						parseInt(
																							item?.meal?.Price
																								? item?.meal?.Price
																								: 0
																						)
																					)
																					.reduce((prev, next) => prev + next)
																		) +
																			Number(
																				location.state.formData.childs &&
																					location.state.formData.childs
																						?.length > 0 &&
																					location.state.formData.childs
																						.map((item) =>
																							parseInt(
																								item?.meal?.Price
																									? item?.meal?.Price
																									: 0
																							)
																						)
																						.reduce((prev, next) => prev + next)
																			)
																	)
																) +
																Number(
																	Math.round(
																		Number(
																			location.state.formData.adults &&
																				location.state.formData.adults?.length >
																					0 &&
																				location.state.formData.adults
																					.map((item) =>
																						parseInt(
																							item?.seat?.Price
																								? item?.seat?.Price
																								: 0
																						)
																					)
																					.reduce((prev, next) => prev + next)
																		) +
																			Number(
																				location.state.formData.childs &&
																					location.state.formData.childs
																						?.length > 0 &&
																					location.state.formData.childs
																						.map((item) =>
																							parseInt(
																								item?.seat?.Price
																									? item?.seat?.Price
																									: 0
																							)
																						)
																						.reduce((prev, next) => prev + next)
																			)
																	)
																) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: location.state.OneflightData.Airline_Code
														? Number(
																Math.round(
																	fareQuoteEtrav
																		? fareQuoteEtrav?.result
																				?.AirRepriceResponses[0].Flight.Fares
																				.Total_Amount
																		: location.state.OneflightData.Fares
																				.Total_Amount
																)
														  ) +
																Number(
																	Math.round(
																		location.state.OneflightData.Supplier ===
																			'ETR'
																			? location.state.OneflightData.Fares
																					.Warning === null ||
																			  location.state.OneflightData.Fares
																					.Warning === ''
																				? 0
																				: location.state.OneflightData.Fares
																						.fdMarkup
																			: 0
																	)
																) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: location.state.OneflightData.Supplier === 'TJK'
														? Number(
																Math.round(
																	farequotetripjack
																		? farequotetripjack?.result?.totalPriceInfo
																				?.totalFareDetail?.Total_Amount
																		: location.state.OneflightData
																				?.totalPriceList?.fd?.Total_Amount
																)
														  ) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: '',
												}}>
												<span style={{ marginRight: 5, marginLeft: 5 }}>
													{'₹'}
												</span>
												{location.state.OneflightData.AirlineCode
													? Number(
															Math.round(
																fareQuote
																	? fareQuote?.result?.Response?.Results.Fare
																			.Total_Amount
																	: location.state.OneflightData.Fare
																			.Total_Amount
															)
													  ) -
													  Number(
															Math.round(
																fareQuote
																	? fareQuote?.result?.Response?.Results.Fare
																			.MFB_Discount
																	: location.state.OneflightData.Fare
																			.MFB_Discount
															)
													  ) +
													  Number(
															Math.round(
																Number(
																	location.state.formData.adults &&
																		location.state.formData.adults?.length >
																			0 &&
																		location.state.formData.adults
																			.map((item) =>
																				parseInt(
																					item?.baggage?.Price
																						? item?.baggage?.Price
																						: 0
																				)
																			)
																			.reduce((prev, next) => prev + next)
																) +
																	Number(
																		location.state.formData.childs &&
																			location.state.formData.childs?.length >
																				0 &&
																			location.state.formData.childs
																				.map((item) =>
																					parseInt(
																						item?.baggage?.Price
																							? item?.baggage?.Price
																							: 0
																					)
																				)
																				.reduce((prev, next) => prev + next)
																	)
															)
													  ) +
													  Number(
															Math.round(
																Number(
																	location.state.formData.adults &&
																		location.state.formData.adults?.length >
																			0 &&
																		location.state.formData.adults
																			.map((item) =>
																				parseInt(
																					item?.meal?.Price
																						? item?.meal?.Price
																						: 0
																				)
																			)
																			.reduce((prev, next) => prev + next)
																) +
																	Number(
																		location.state.formData.childs &&
																			location.state.formData.childs?.length >
																				0 &&
																			location.state.formData.childs
																				.map((item) =>
																					parseInt(
																						item?.meal?.Price
																							? item?.meal?.Price
																							: 0
																					)
																				)
																				.reduce((prev, next) => prev + next)
																	)
															)
													  ) +
													  Number(
															Math.round(
																Number(
																	location.state.formData.adults &&
																		location.state.formData.adults?.length >
																			0 &&
																		location.state.formData.adults
																			.map((item) =>
																				parseInt(
																					item?.seat?.Price
																						? item?.seat?.Price
																						: 0
																				)
																			)
																			.reduce((prev, next) => prev + next)
																) +
																	Number(
																		location.state.formData.childs &&
																			location.state.formData.childs?.length >
																				0 &&
																			location.state.formData.childs
																				.map((item) =>
																					parseInt(
																						item?.seat?.Price
																							? item?.seat?.Price
																							: 0
																					)
																				)
																				.reduce((prev, next) => prev + next)
																	)
															)
													  )
													: location.state.OneflightData.Airline_Code
													? Number(
															Math.round(
																fareQuoteEtrav
																	? fareQuoteEtrav?.result
																			?.AirRepriceResponses[0].Flight.Fares
																			.Total_Amount
																	: location.state.OneflightData.Fares
																			.Total_Amount
															)
													  ) +
													  Number(
															Math.round(
																location.state.OneflightData.Supplier === 'ETR'
																	? location.state.OneflightData.Fares
																			.Warning === null ||
																	  location.state.OneflightData.Fares
																			.Warning === ''
																		? 0
																		: location.state.OneflightData.Fares
																				.fdMarkup
																	: 0
															)
													  )
													: location.state.OneflightData.Supplier === 'TJK'
													? Number(
															Math.round(
																farequotetripjack
																	? farequotetripjack?.result?.totalPriceInfo
																			?.totalFareDetail.Total_Amount
																	: location.state.OneflightData?.totalPriceList
																			?.fd?.Total_Amount
															)
													  )
													: ''}
											</span>
										</>
									) : (
										// RoundtripPay with Offline
										<>
											<FormControlLabel
												style={{ marginRight: 0 }}
												value='offline'
												control={
													<Radio
														checked={offline}
														onClick={handleHold}
														disabled={
															etrav2
																? Number(
																		Number(
																			Math.round(
																				fareQuoteEtrav
																					? fareQuoteEtrav?.result
																							?.AirRepriceResponses[1].Flight
																							.Fares.Total_Amount
																					: location.state.selectedReturn[0]
																							.Fares.Total_Amount
																			)
																		) +
																			Number(
																				Math.round(
																					fareQuoteEtrav
																						? fareQuoteEtrav?.result
																								?.AirRepriceResponses[0].Flight
																								.Fares.Total_Amount
																						: location.state.selectedonward[0]
																								.Fares.Total_Amount
																				)
																			)
																  ) <
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: tbo2
																? Number(
																		Number(
																			Math.round(
																				fareQuoteReturn
																					? fareQuoteReturn?.result.Response
																							.Results.Fare.Total_Amount
																					: location.state.selectedReturn[0]
																							.Fare.Total_Amount
																			)
																		) +
																			Number(
																				Math.round(
																					fareQuote
																						? fareQuote?.result.Response.Results
																								.Fare.Total_Amount
																						: location.state.selectedonward[0]
																								.Fare.Total_Amount
																				)
																			) -
																			Number(
																				Number(
																					Math.round(
																						fareQuoteReturn
																							? fareQuoteReturn?.result.Response
																									.Results.Fare.MFB_Discount
																							: location.state.selectedReturn[0]
																									.Fare.MFB_Discount
																					)
																				) +
																					Number(
																						Math.round(
																							fareQuote
																								? fareQuote?.result.Response
																										.Results.Fare.MFB_Discount
																								: location.state
																										.selectedonward[0].Fare
																										.MFB_Discount
																						)
																					)
																			)
																  ) <
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: etrvotbor
																? Number(
																		Number(
																			Math.round(
																				location.state.selectedReturn[0].Fare
																					.Total_Amount
																			)
																		) +
																			Number(
																				Math.round(
																					location.state.selectedonward[0].Fares
																						.Total_Amount
																				)
																			)
																  ) <
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: etravrtboo
																? Number(
																		Number(
																			Math.round(
																				location.state.selectedReturn[0].Fares
																					.Total_Amount
																			)
																		) +
																			Number(
																				Math.round(
																					location.state.selectedonward[0].Fare
																						.Total_Amount
																				)
																			)
																  ) <
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: location.state.intFlight
																? Number(
																		Math.round(
																			Number(
																				Math.round(
																					fareQuote
																						? fareQuote?.result?.Response
																								.Results.Fare.Total_Amount
																						: location.state.intFlight.Fare
																								.Total_Amount
																				)
																			)
																		)
																  ) -
																		Number(
																			Math.round(
																				Number(
																					Math.round(
																						fareQuote
																							? fareQuote?.result?.Response
																									.Results.Fare.MFB_Discount
																							: location.state.intFlight.Fare
																									.MFB_Discount
																					)
																				)
																			)
																		) <=
																  Number(
																		Math.round(
																			walletagent &&
																				walletagent.result.balanceAmount
																		)
																  )
																	? false
																	: true
																: ''
														}
													/>
												}
											/>
											<span
												style={{
													opacity: etrav2
														? Number(
																Number(
																	Math.round(
																		fareQuoteEtrav
																			? fareQuoteEtrav?.result
																					?.AirRepriceResponses[1].Flight.Fares
																					.Total_Amount
																			: location.state.selectedReturn[0].Fares
																					.Total_Amount
																	)
																) +
																	Number(
																		Math.round(
																			fareQuoteEtrav
																				? fareQuoteEtrav?.result
																						?.AirRepriceResponses[0].Flight
																						.Fares.Total_Amount
																				: location.state.selectedonward[0].Fares
																						.Total_Amount
																		)
																	)
														  ) <
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: tbo2
														? Number(
																Number(
																	Math.round(
																		fareQuoteReturn
																			? fareQuoteReturn?.result.Response.Results
																					.Fare.Total_Amount
																			: location.state.selectedReturn[0].Fare
																					.Total_Amount
																	)
																) +
																	Number(
																		Math.round(
																			fareQuote
																				? fareQuote?.result.Response.Results
																						.Fare.Total_Amount
																				: location.state.selectedonward[0].Fare
																						.Total_Amount
																		)
																	) -
																	Number(
																		Number(
																			Math.round(
																				fareQuoteReturn
																					? fareQuoteReturn?.result.Response
																							.Results.Fare.MFB_Discount
																					: location.state.selectedReturn[0]
																							.Fare.MFB_Discount
																			)
																		) +
																			Number(
																				Math.round(
																					fareQuote
																						? fareQuote?.result.Response.Results
																								.Fare.MFB_Discount
																						: location.state.selectedonward[0]
																								.Fare.MFB_Discount
																				)
																			)
																	)
														  ) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: etrvotbor
														? Number(
																Number(
																	Math.round(
																		location.state.selectedReturn[0].Fare
																			.Total_Amount
																	)
																) +
																	Number(
																		Math.round(
																			location.state.selectedonward[0].Fares
																				.Total_Amount
																		)
																	)
														  ) <
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: etravrtboo
														? Number(
																Number(
																	Math.round(
																		location.state.selectedReturn[0].Fares
																			.Total_Amount
																	)
																) +
																	Number(
																		Math.round(
																			location.state.selectedonward[0].Fare
																				.Total_Amount
																		)
																	)
														  ) <
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: location.state.intFlight
														? Number(
																Math.round(
																	Number(
																		Math.round(
																			fareQuote
																				? fareQuote?.result?.Response.Results
																						.Fare.Total_Amount
																				: location.state.intFlight.Fare
																						.Total_Amount
																		)
																	)
																)
														  ) -
																Number(
																	Math.round(
																		Number(
																			Math.round(
																				fareQuote
																					? fareQuote?.result?.Response.Results
																							.Fare.MFB_Discount
																					: location.state.intFlight.Fare
																							.MFB_Discount
																			)
																		)
																	)
																) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: '',
												}}>
												Offline Amount
											</span>

											<span
												style={{
													marginLeft: 5,
													opacity: etrav2
														? Number(
																Number(
																	Math.round(
																		fareQuoteEtrav
																			? fareQuoteEtrav?.result
																					?.AirRepriceResponses[1].Flight.Fares
																					.Total_Amount
																			: location.state.selectedReturn[0].Fares
																					.Total_Amount
																	)
																) +
																	Number(
																		Math.round(
																			fareQuoteEtrav
																				? fareQuoteEtrav?.result
																						?.AirRepriceResponses[0].Flight
																						.Fares.Total_Amount
																				: location.state.selectedonward[0].Fares
																						.Total_Amount
																		)
																	)
														  ) <
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: tbo2
														? Number(
																Number(
																	Math.round(
																		fareQuoteReturn
																			? fareQuoteReturn?.result.Response.Results
																					.Fare.Total_Amount
																			: location.state.selectedReturn[0].Fare
																					.Total_Amount
																	)
																) +
																	Number(
																		Math.round(
																			fareQuote
																				? fareQuote?.result.Response.Results
																						.Fare.Total_Amount
																				: location.state.selectedonward[0].Fare
																						.Total_Amount
																		)
																	) -
																	Number(
																		Number(
																			Math.round(
																				fareQuoteReturn
																					? fareQuoteReturn?.result.Response
																							.Results.Fare.MFB_Discount
																					: location.state.selectedReturn[0]
																							.Fare.MFB_Discount
																			)
																		) +
																			Number(
																				Math.round(
																					fareQuote
																						? fareQuote?.result.Response.Results
																								.Fare.MFB_Discount
																						: location.state.selectedonward[0]
																								.Fare.MFB_Discount
																				)
																			)
																	)
														  ) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: location.state.intFlight
														? Number(
																Math.round(
																	Number(
																		Math.round(
																			fareQuote
																				? fareQuote?.result?.Response.Results
																						.Fare.Total_Amount
																				: location.state.intFlight.Fare
																						.Total_Amount
																		)
																	)
																)
														  ) -
																Number(
																	Math.round(
																		Number(
																			Math.round(
																				fareQuote
																					? fareQuote?.result?.Response.Results
																							.Fare.MFB_Discount
																					: location.state.intFlight.Fare
																							.MFB_Discount
																			)
																		)
																	)
																) <=
														  Number(
																Math.round(
																	walletagent &&
																		walletagent.result.balanceAmount
																)
														  )
															? ''
															: 0.3
														: '',
												}}>
												<span style={{ marginRight: 5, marginLeft: 5 }}>
													{'₹'}
												</span>
												{etrav2
													? Number(
															Number(
																Math.round(
																	fareQuoteEtrav
																		? fareQuoteEtrav?.result
																				?.AirRepriceResponses[1].Flight.Fares
																				.Total_Amount
																		: location.state.selectedReturn[0].Fares
																				.Total_Amount
																)
															) +
																Number(
																	Math.round(
																		fareQuoteEtrav
																			? fareQuoteEtrav?.result
																					?.AirRepriceResponses[0].Flight.Fares
																					.Total_Amount
																			: location.state.selectedonward[0].Fares
																					.Total_Amount
																	)
																)
													  )
													: tbo2
													? Number(
															Number(
																Math.round(
																	fareQuoteReturn
																		? fareQuoteReturn?.result.Response.Results
																				.Fare.Total_Amount
																		: location.state.selectedReturn[0].Fare
																				.Total_Amount
																)
															) +
																Number(
																	Math.round(
																		fareQuote
																			? fareQuote?.result.Response.Results.Fare
																					.Total_Amount
																			: location.state.selectedonward[0].Fare
																					.Total_Amount
																	)
																) -
																Number(
																	Number(
																		Math.round(
																			fareQuoteReturn
																				? fareQuoteReturn?.result.Response
																						.Results.Fare.MFB_Discount
																				: location.state.selectedReturn[0].Fare
																						.MFB_Discount
																		)
																	) +
																		Number(
																			Math.round(
																				fareQuote
																					? fareQuote?.result.Response.Results
																							.Fare.MFB_Discount
																					: location.state.selectedonward[0]
																							.Fare.MFB_Discount
																			)
																		)
																)
													  )
													: etrvotbor
													? Number(
															Number(
																Math.round(
																	location.state.selectedReturn[0].Fare
																		.Total_Amount
																)
															) +
																Number(
																	Math.round(
																		location.state.selectedonward[0].Fares
																			.Total_Amount
																	)
																)
													  )
													: etravrtboo
													? Number(
															Number(
																Math.round(
																	location.state.selectedReturn[0].Fares
																		.Total_Amount
																)
															) +
																Number(
																	Math.round(
																		location.state.selectedonward[0].Fare
																			.Total_Amount
																	)
																)
													  )
													: location.state.intFlight
													? Number(
															Math.round(
																Number(
																	Math.round(
																		fareQuote
																			? fareQuote?.result?.Response.Results.Fare
																					.Total_Amount
																			: location.state.intFlight.Fare
																					.Total_Amount
																	)
																)
															)
													  ) -
													  Number(
															Math.round(
																Number(
																	Math.round(
																		fareQuote
																			? fareQuote?.result?.Response.Results.Fare
																					.MFB_Discount
																			: location.state.intFlight.Fare
																					.MFB_Discount
																	)
																)
															)
													  )
													: ''}
											</span>
										</>
									)}
								</>
							) : (
								<>
									{/* //{' '} */}
									<p>For Bookings Please call or Whatsapp at +91 7007102425</p>
									{/* <p>For Bookings Please call or Whatsapp at +91 7800380872</p> */}
								</>
							)}

							{/* <FormControlLabel
								style={{ marginRight: 0, marginLeft: 10 }}
								value='payworldline'
								control={
									<Radio
										checked={payworldline}
										onClick={handleHold}
										style={{ marginLeft: 10 }}
									/>
								}
								// label='Pay Online'
							/>
							<span>Pay With EaseBuzz</span>
							<span style={{ marginLeft: 5, color: 'blue' }}>
								₹
								{location.state.OneflightData
									? location.state.OneflightData.Supplier === 'ETR'
										? Number(
												Math.round(
													Number(
														Number(
															Math.round(
																fareQuoteEtrav
																	? fareQuoteEtrav?.result
																			?.AirRepriceResponses[0].Flight.Fares
																			.Total_Amount
																	: location.state.OneflightData.Fares
																			.Total_Amount
															)
														) +
															Number(
																Math.round(
																	(Number(
																		Number(
																			Math.round(
																				fareQuoteEtrav
																					? fareQuoteEtrav?.result
																							?.AirRepriceResponses[0].Flight
																							.Fares.Total_Amount
																					: location.state.OneflightData.Fares
																							.Total_Amount
																			)
																		)
																	) *
																		2.5) /
																		100
																)
															)
													)
												)
										  )
										: location.state.OneflightData.Supplier === 'TBO'
										? Number(
												Math.round(
													Number(
														Number(
															Math.round(
																fareQuote
																	? fareQuote?.result?.Response?.Results.Fare
																			.Total_Amount
																	: location.state.OneflightData.Fare
																			.Total_Amount
															)
														) -
															Number(
																Math.round(
																	fareQuote
																		? fareQuote?.result?.Response?.Results.Fare
																				.MFB_Discount
																		: location.state.OneflightData.Fare
																				.MFB_Discount
																)
															) +
															Number(
																Math.round(
																	(Number(
																		Number(
																			Math.round(
																				fareQuote
																					? fareQuote?.result?.Response?.Results
																							.Fare.Total_Amount
																					: location.state.OneflightData.Fare
																							.Total_Amount
																			)
																		) -
																			Number(
																				Math.round(
																					fareQuote
																						? fareQuote?.result?.Response
																								?.Results.Fare.MFB_Discount
																						: location.state.OneflightData.Fare
																								.MFB_Discount
																				)
																			)
																	) *
																		2.5) /
																		100
																)
															) +
															Number(
																location.state.baggageInfo &&
																	location.state.baggageInfo?.length > 0 &&
																	location.state.baggageInfo
																		.map((item) => parseInt(item.split('|')[2]))
																		.reduce((prev, next) => prev + next)
															)
													)
												)
										  )
										: location.state.OneflightData.Supplier === 'TJK'
										? Number(
												Math.round(
													Number(
														Number(
															Math.round(
																location.state.OneflightData.totalPriceList.fd
																	.Total_Amount
															)
														) -
															Number(
																Math.round(
																	location.state.OneflightData.totalPriceList.fd
																		.agentMarkup
																)
															) +
															Number(
																Math.round(
																	(Number(
																		Number(
																			Math.round(
																				location.state.OneflightData
																					.totalPriceList.fd.Total_Amount
																			)
																		) -
																			Number(
																				Math.round(
																					location.state.OneflightData
																						.totalPriceList.fd.agentMarkup
																				)
																			)
																	) *
																		2.5) /
																		100
																)
															)
													)
												)
										  )
										: ''
									: location.state.intFlight
									? location.state.intFlight.Supplier === 'TBO'
										? Number(
												Math.round(
													Number(
														Math.round(
															Number(
																Math.round(
																	fareQuote
																		? fareQuote?.result?.Response.Results.Fare
																				.Total_Amount
																		: location.state.intFlight.Fare.Total_Amount
																)
															)
														)
													) -
														Number(
															Math.round(
																Number(
																	Math.round(
																		fareQuote
																			? fareQuote?.result?.Response.Results.Fare
																					.MFB_Discount
																			: location.state.intFlight.Fare
																					.MFB_Discount
																	)
																)
															)
														) +
														Number(
															(Number(
																Number(
																	Math.round(
																		Number(
																			Math.round(
																				fareQuote
																					? fareQuote?.result?.Response.Results
																							.Fare.Total_Amount
																					: location.state.intFlight.Fare
																							.Total_Amount
																			)
																		)
																	)
																) -
																	Number(
																		Math.round(
																			Number(
																				Math.round(
																					fareQuote
																						? fareQuote?.result?.Response
																								.Results.Fare.MFB_Discount
																						: location.state.intFlight.Fare
																								.MFB_Discount
																				)
																			)
																		)
																	)
															) *
																2.5) /
																100
														)
												)
										  )
										: location.state.intFlight.Supplier === 'ETR'
										? Number(
												Math.round(
													Number(
														Number(
															Math.round(
																fareQuoteEtrav
																	? fareQuoteEtrav?.result
																			?.AirRepriceResponses[0].Flight.Fares
																			.Total_Amount
																	: location.state.intFlight.Fares.Total_Amount
															)
														) +
															Number(
																Math.round(
																	(Number(
																		Number(
																			Math.round(
																				fareQuoteEtrav
																					? fareQuoteEtrav?.result
																							?.AirRepriceResponses[0].Flight
																							.Fares.Total_Amount
																					: location.state.intFlight.Fares
																							.Total_Amount
																			)
																		)
																	) *
																		2.5) /
																		100
																)
															)
													)
												)
										  )
										: ''
									: Number(
											Math.round(
												Number(
													Number(
														Math.round(
															location.state.selectedonward[0].Airline_Code
																? fareQuoteEtrav
																	? fareQuoteEtrav?.result
																			?.AirRepriceResponses[0]?.Flight?.Fares
																			.Total_Amount
																	: location.state.selectedonward[0].Fares
																			.Total_Amount
																: location.state.selectedonward[0].AirlineCode
																? fareQuote
																	? fareQuote?.result?.Response?.Results?.Fare
																			?.Total_Amount
																	: location.state.selectedonward[0].Fare
																			.Total_Amount
																: 0
														)
													) +
														Number(
															Math.round(
																location.state.selectedReturn[0].Airline_Code
																	? fareQuoteEtrav
																		? fareQuoteEtrav?.result
																				?.AirRepriceResponses[1]?.Flight?.Fares
																				.Total_Amount
																		: location.state.selectedReturn[0].Fares
																				.Total_Amount
																	: location.state.selectedReturn[0].AirlineCode
																	? fareQuoteReturn
																		? fareQuoteReturn?.result?.Response?.Results
																				?.Fare?.Total_Amount
																		: location.state.selectedReturn[0].Fare
																				.Total_Amount
																	: 0
															) -
																Number(
																	Math.round(
																		location.state.selectedReturn[0]
																			.Airline_Code
																			? 0
																			: location.state.selectedReturn[0]
																					.AirlineCode
																			? fareQuoteReturn
																				? fareQuoteReturn?.result?.Response
																						?.Results?.Fare?.MFB_Discount
																				: location.state.selectedReturn[0].Fare
																						.MFB_Discount
																			: 0
																	)
																) -
																Number(
																	Math.round(
																		location.state.selectedonward[0]
																			.Airline_Code
																			? 0
																			: location.state.selectedonward[0]
																					.AirlineCode
																			? fareQuote
																				? fareQuote?.result?.Response?.Results
																						?.Fare?.MFB_Discount
																				: location.state.selectedonward[0].Fare
																						.MFB_Discount
																			: 0
																	)
																)
														) +
														(Number(
															Number(
																Math.round(
																	location.state.selectedonward[0].Airline_Code
																		? fareQuoteEtrav
																			? fareQuoteEtrav?.result
																					?.AirRepriceResponses[0]?.Flight
																					?.Fares?.Total_Amount
																			: location.state.selectedonward[0].Fares
																					.Total_Amount
																		: location.state.selectedonward[0]
																				.AirlineCode
																		? fareQuote
																			? fareQuote?.result?.Response?.Results
																					?.Fare.Total_Amount
																			: location.state.selectedonward[0].Fare
																					.Total_Amount
																		: 0
																)
															) +
																Number(
																	Math.round(
																		location.state.selectedReturn[0]
																			.Airline_Code
																			? fareQuoteEtrav
																				? fareQuoteEtrav?.result
																						?.AirRepriceResponses[1].Flight
																						.Fares.Total_Amount
																				: location.state.selectedReturn[0].Fares
																						.Total_Amount
																			: location.state.selectedReturn[0]
																					.AirlineCode
																			? fareQuoteReturn
																				? fareQuoteReturn?.result?.Response
																						?.Results?.Fare?.Total_Amount
																				: location.state.selectedReturn[0].Fare
																						.Total_Amount
																			: 0
																	)
																) -
																Number(
																	Math.round(
																		location.state.selectedReturn[0]
																			.Airline_Code
																			? 0
																			: location.state.selectedReturn[0]
																					.AirlineCode
																			? fareQuoteReturn
																				? fareQuoteReturn?.result?.Response
																						?.Results?.Fare?.MFB_Discount
																				: location.state.selectedReturn[0].Fare
																						.MFB_Discount
																			: 0
																	)
																) -
																Number(
																	Math.round(
																		location.state.selectedonward[0]
																			.Airline_Code
																			? 0
																			: location.state.selectedonward[0]
																					.AirlineCode
																			? fareQuote
																				? fareQuote?.result?.Response?.Results
																						?.Fare?.MFB_Discount
																				: location.state.selectedonward[0].Fare
																						.MFB_Discount
																			: 0
																	)
																)
														) *
															2.5) /
															100
												)
											)
									  )}
							</span> */}
						</Box>
						{/* Submit and Edit Button */}
						<Grid
							container
							spacing={2}
							style={{ marginBottom: 60, marginTop: 20 }}>
							{/* <Grid item xs={4} lg={2}>
								<Button
									className={classes.createButton}
									onClick={edit}
									style={{ marginTop: 10, fontSize: 14 }}
									// disabled={isSubmitting}
								>
									EDIT
								</Button>
							</Grid> */}
							<Grid item xs={8} lg={4}>
								<Button
									className={classes.createButton}
									onClick={handleFormSubmit}
									style={{ marginTop: 10, fontSize: 14 }}
									disabled={offline || pay || payworldline ? false : true}>
									PROCESS TO PAYMENT
								</Button>
							</Grid>
							<Grid item xs={4}></Grid>
						</Grid>
					</Form>
				)}
			</Formik>
			{/* <ErrorModal /> */}
			<EaseBuzzModal />
			<WorldlinePG
				handlePayment={handlePayment}
				amountdebit={
					location.state.OneflightData?.AirlineCode
						? Number(
								Math.round(
									fareQuote
										? fareQuote?.result?.Response?.Results.Fare.Total_Amount
										: location.state.OneflightData.Fare.Total_Amount
								)
						  ) -
						  Number(
								Math.round(
									fareQuote
										? fareQuote?.result?.Response?.Results.Fare.MFB_Discount
										: location.state.OneflightData.Fare.MFB_Discount
								)
						  ) +
						  Number(
								Math.round(
									Number(
										location.state.formData.adults &&
											location.state.formData.adults?.length > 0 &&
											location.state.formData.adults
												.map((item) =>
													parseInt(
														item?.baggage?.Price ? item?.baggage?.Price : 0
													)
												)
												.reduce((prev, next) => prev + next)
									) +
										Number(
											location.state.formData.childs &&
												location.state.formData.childs?.length > 0 &&
												location.state.formData.childs
													.map((item) =>
														parseInt(
															item?.baggage?.Price ? item?.baggage?.Price : 0
														)
													)
													.reduce((prev, next) => prev + next)
										)
								)
						  ) +
						  Number(
								Math.round(
									Number(
										location.state.formData.adults &&
											location.state.formData.adults?.length > 0 &&
											location.state.formData.adults
												.map((item) =>
													parseInt(item?.meal?.Price ? item?.meal?.Price : 0)
												)
												.reduce((prev, next) => prev + next)
									) +
										Number(
											location.state.formData.childs &&
												location.state.formData.childs?.length > 0 &&
												location.state.formData.childs
													.map((item) =>
														parseInt(item?.meal?.Price ? item?.meal?.Price : 0)
													)
													.reduce((prev, next) => prev + next)
										)
								)
						  ) +
						  Number(
								Math.round(
									Number(
										location.state.formData.adults &&
											location.state.formData.adults?.length > 0 &&
											location.state.formData.adults
												.map((item) =>
													parseInt(item?.seat?.Price ? item?.seat?.Price : 0)
												)
												.reduce((prev, next) => prev + next)
									) +
										Number(
											location.state.formData.childs &&
												location.state.formData.childs?.length > 0 &&
												location.state.formData.childs
													.map((item) =>
														parseInt(item?.seat?.Price ? item?.seat?.Price : 0)
													)
													.reduce((prev, next) => prev + next)
										)
								)
						  )
						: location.state.OneflightData?.Airline_Code
						? Number(
								Math.round(
									fareQuoteEtrav
										? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight
												.Fares.Total_Amount
										: location.state.OneflightData.Fares.Total_Amount
								)
						  )
						: location.state.OneflightData?.Supplier === 'TJK'
						? Number(
								Math.round(
									farequotetripjack
										? farequotetripjack?.result?.totalPriceInfo?.totalFareDetail
												.Total_Amount
										: location.state.OneflightData?.totalPriceList?.fd
												?.Total_Amount
								)
						  )
						: etrav2
						? Number(
								Number(
									Math.round(
										fareQuoteEtrav
											? fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight
													.Fares.Total_Amount
											: location.state.selectedReturn[0].Fares.Total_Amount
									)
								) +
									Number(
										Math.round(
											fareQuoteEtrav
												? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight
														.Fares.Total_Amount
												: location.state.selectedonward[0].Fares.Total_Amount
										)
									)
						  )
						: tbo2
						? Number(
								Number(
									Math.round(
										fareQuoteReturn
											? fareQuoteReturn?.result?.Response?.Results?.Fare
													.Total_Amount
											: location.state.selectedReturn[0].Fare.Total_Amount
									)
								) +
									Number(
										Math.round(
											fareQuote
												? fareQuote?.result?.Response?.Results?.Fare
														?.Total_Amount
												: location.state.selectedonward[0].Fare.Total_Amount
										)
									) -
									Number(
										Math.round(
											fareQuoteReturn
												? fareQuoteReturn?.result?.Response?.Results?.Fare
														.MFB_Discount
												: location.state.selectedReturn[0].Fare.MFB_Discount
										)
									) -
									Number(
										Math.round(
											fareQuote
												? fareQuote?.result?.Response?.Results?.Fare
														?.MFB_Discount
												: location.state.selectedonward[0].Fare.MFB_Discount
										)
									)
						  )
						: etrvotbor
						? Number(
								Number(
									Math.round(
										location?.state?.selectedReturn[0]?.Fare?.Total_Amount
									)
								) +
									Number(
										Math.round(
											location?.state?.selectedonward[0]?.Fares?.Total_Amount
										)
									)
						  )
						: etravrtboo
						? Number(
								Number(
									Math.round(
										location.state?.selectedReturn[0]?.Fares?.Total_Amount
									)
								) +
									Number(
										Math.round(
											location.state?.selectedonward[0]?.Fare?.Total_Amount
										)
									)
						  )
						: location.state.intFlight
						? location.state.intFlight.Supplier === 'TBO'
							? Number(
									Math.round(
										fareQuote
											? fareQuote?.result?.Response?.Results.Fare.Total_Amount
											: location.state.intFlight.Fare.Total_Amount
									)
							  ) -
							  Number(
									Math.round(
										fareQuote
											? fareQuote?.result?.Response?.Results.Fare.MFB_Discount
											: location.state.intFlight.Fare.MFB_Discount
									)
							  )
							: location.state.intFlight.Supplier === 'ETR'
							? Number(
									Math.round(
										Number(
											Number(
												Math.round(
													fareQuoteEtrav
														? fareQuoteEtrav?.result?.AirRepriceResponses[0]
																.Flight.Fares.Total_Amount
														: location.state.intFlight.Fares.Total_Amount
												)
											) +
												Number(
													Math.round(
														(Number(
															Number(
																Math.round(
																	fareQuoteEtrav
																		? fareQuoteEtrav?.result
																				?.AirRepriceResponses[0].Flight.Fares
																				.Total_Amount
																		: location.state.intFlight.Fares
																				.Total_Amount
																)
															)
														) *
															2.5) /
															100
													)
												)
										)
									)
							  )
							: ''
						: ''
				}
			/>
		</>
	);
};

export default FlightConfirmForm;
