const addBooking = (
	location,
	agentlogin,
	payment,
	payableAmout,
	priceDetails,
	selectedFlight,
	dispatch,
	FlightAddBookingDispatch,
	BookingFormData,
	fareQuoteEtrav,
	fareQuote,
	farequotetripjack,
	handlePayment,
	ETRPA,
	TBOPA,
	TJKPA
) => {
	const FareQuotePrice = Number(
		Math.round(
			fareQuoteEtrav
				? fareQuoteEtrav?.result?.AirRepriceResponses[0]?.Flight.Fares
						.Total_Amount
				: fareQuote
				? fareQuote?.result?.Response?.Results?.Fare.Total_Amount
				: farequotetripjack
				? farequotetripjack?.result.totalPriceInfo.totalFareDetail.Total_Amount
				: ''
		)
	);
	const OriginalPrice = Number(
		Math.round(
			location.state.OneflightData.Fares
				? location.state.OneflightData.Fares.Total_Amount
				: location.state.OneflightData.Fare
				? location.state.OneflightData.Fare.Total_Amount
				: location.state.OneflightData?.totalPriceList.fd.Total_Amount
		)
	);
	let formaddBooking = {
		orderId: location.state.orderID,
		supplier: location.state.OneflightData.Supplier,
		userEmail: location.state.formData.email,
		userPhone: location.state.formData.phone,
		user_type: agentlogin && agentlogin.status === 200 ? 'agent' : 'guest',
		origin: location.state.body.Segments[0].Origin,
		destination: location.state.body.Segments[0].Destination,
		travelDate: location.state.body.Segments[0].PreferredDepartureTime,
		returnDate: '',
		journeyType: location.state.body.JourneyType === 1 ? 'OneWay' : '',
		origin1: '',
		destination1: '',
		travelDate1: '',
		origin2: '',
		destination2: '',
		travelDate2: '',
		origin3: '',
		destination3: '',
		travelDate3: '',
		PGCharge:
			payment === 'payworldline'
				? location.state.OneflightData.Supplier === 'TBO'
					? Number((Number(TBOPA) * 2.5) / 100)
					: location.state.OneflightData.Supplier === 'ETR'
					? Number((Number(ETRPA) * 2.5) / 100)
					: location.state.OneflightData.Supplier === 'TJK'
					? Number((Number(TJKPA) * 2.5) / 100)
					: ''
				: 0,
		class: 'Economy',
		nop: `${location.state.body.AdultCount} | ${location.state.body.ChildCount} | ${location.state.body.InfantCount} `,
		airlineType: '',
		carrier: location.state.OneflightData.AirlineCode
			? location.state.OneflightData.AirlineCode
			: location.state.OneflightData.Airline_Code
			? location.state.OneflightData.Airline_Code
			: location.state.OneflightData.Supplier === 'TJK'
			? location.state.OneflightData.sI[0].fD.aI.code
			: '',
		fareType: 'R',
		eTicketType: 'eTicket',
		actualFare:
			location.state.OneflightData.Supplier === 'TBO'
				? Number(Math.round(location.state.OneflightData.Fare.Total_Amount)) -
				  Number(Math.round(location.state.OneflightData.Fare.markup)) -
				  Number(Math.round(location.state.OneflightData.Fare.partnerMarkup)) -
				  Number(Math.round(location.state.OneflightData.Fare.agentMarkup)) -
				  Number(Math.round(location.state.OneflightData.Fare.MFB_Discount))
				: location.state.OneflightData.Supplier === 'ETR'
				? Number(Math.round(location.state.OneflightData.Fares.Total_Amount)) -
				  Number(Math.round(location.state.OneflightData.Fares.markup)) -
				  Number(Math.round(location.state.OneflightData.Fares.partnerMarkup)) -
				  Number(Math.round(location.state.OneflightData.Fares.agentMarkup))
				: location.state.OneflightData.Supplier === 'TJK'
				? Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.Total_Amount
						)
				  ) -
				  Number(
						Math.round(location.state.OneflightData.totalPriceList.fd.markup)
				  ) -
				  Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.partnerMarkup
						)
				  ) -
				  Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.agentMarkup
						)
				  )
				: '',
		markup:
			location.state.OneflightData.Supplier === 'TBO'
				? Math.round(location.state.OneflightData.Fare.markup)
				: location.state.OneflightData.Supplier === 'ETR'
				? Math.round(location.state.OneflightData.Fares.markup)
				: location.state.OneflightData.Supplier === 'TJK'
				? Number(
						Math.round(location.state.OneflightData.totalPriceList.fd.markup)
				  )
				: '',
		baseFare:
			location.state.OneflightData.Supplier === 'TBO'
				? Number(Math.round(location.state.OneflightData.Fare.Total_Amount)) -
				  Number(Math.round(location.state.OneflightData.Fare.partnerMarkup)) -
				  Number(Math.round(location.state.OneflightData.Fare.agentMarkup)) -
				  Number(Math.round(location.state.OneflightData.Fare.MFB_Discount))
				: location.state.OneflightData.Supplier === 'ETR'
				? Number(Math.round(location.state.OneflightData.Fares.Total_Amount)) -
				  Number(Math.round(location.state.OneflightData.Fares.partnerMarkup)) -
				  Number(Math.round(location.state.OneflightData.Fares.agentMarkup))
				: location.state.OneflightData.Supplier === 'TJK'
				? Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.Total_Amount
						)
				  ) -
				  Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.partnerMarkup
						)
				  ) -
				  Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.agentMarkup
						)
				  )
				: '',
		partnerMarkup:
			location.state.OneflightData.Supplier === 'TBO'
				? Math.round(location.state.OneflightData.Fare.partnerMarkup)
				: location.state.OneflightData.Supplier === 'ETR'
				? Math.round(
						location.state.OneflightData?.Fares?.fdMarkup
							? location.state.OneflightData.Fares.fdMarkup
							: location.state.OneflightData.Fares.partnerMarkup
				  )
				: location.state.OneflightData.Supplier === 'TJK'
				? Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.partnerMarkup
						)
				  )
				: '',
		agentMarkup:
			location.state.OneflightData.Supplier === 'TBO'
				? Math.round(location.state.OneflightData.Fare.agentMarkup)
				: location.state.OneflightData.Supplier === 'ETR'
				? Math.round(location.state.OneflightData.Fares.agentMarkup)
				: location.state.OneflightData.Supplier === 'TJK'
				? Number(
						Math.round(
							location.state.OneflightData.totalPriceList.fd.agentMarkup
						)
				  )
				: '',
		totalFare:
			location.state.OneflightData.Supplier === 'TBO'
				? Number(TBOPA)
				: location.state.OneflightData.Supplier === 'ETR'
				? Number(ETRPA) +
				  Number(
						Number(
							Math.round(
								location.state.OneflightData.Supplier === 'ETR'
									? location.state.OneflightData.Fares.Warning === null ||
									  location.state.OneflightData.Fares.Warning === ''
										? 0
										: location.state.OneflightData.Fares.fdMarkup
									: 0
							)
						)
				  )
				: location.state.OneflightData.Supplier === 'TJK'
				? Number(TJKPA)
				: '',
		payableAmount:
			payment === 'payworldline'
				? Number(payableAmout)
				: Number(payableAmout) -
				  Number(
						location.state.OneflightData.Supplier === 'TBO'
							? Number((Number(TBOPA) * 2.5) / 100)
							: location.state.OneflightData.Supplier === 'ETR'
							? Number(
									Number(
										(Number(ETRPA) +
											Number(location.state.OneflightData?.Fares?.fdMarkup)) *
											2.5
									) / 100
							  )
							: location.state.OneflightData.Supplier === 'TJK'
							? Number((Number(TJKPA) * 2.5) / 100)
							: ''
				  ) +
				  Number(
						location.state.OneflightData.Supplier === 'ETR'
							? location.state.OneflightData.Fares.fdMarkup
							: 0
				  ),
		ticketCost:
			payment === 'payworldline'
				? location.state.OneflightData.Supplier === 'TBO'
					? Number(payableAmout) +
					  Number(Math.round(location.state.OneflightData.Fare.agentMarkup))
					: location.state.OneflightData.Supplier === 'ETR'
					? Number(payableAmout) +
					  Number(Math.round(location.state.OneflightData.Fares.agentMarkup))
					: location.state.OneflightData.Supplier === 'TJK'
					? Number(payableAmout) +
					  Number(
							Math.round(
								location.state.OneflightData.totalPriceList.fd.agentMarkup
							)
					  )
					: ''
				: Number(payableAmout) -
				  Number(
						location.state.OneflightData.Supplier === 'TBO'
							? Number((Number(TBOPA) * 2.5) / 100)
							: location.state.OneflightData.Supplier === 'ETR'
							? Number((Number(ETRPA) * 2.5) / 100)
							: location.state.OneflightData.Supplier === 'TJK'
							? Number((Number(TJKPA) * 2.5) / 100)
							: ''
				  ) +
				  Number(
						Number(
							Math.round(
								location.state.OneflightData.Supplier === 'ETR'
									? location.state.OneflightData.Fares.Warning === null ||
									  location.state.OneflightData.Fares.Warning === ''
										? 0
										: location.state.OneflightData.Fares.fdMarkup
									: 0
							)
						)
				  ),
		totalEarn: 0,
		cashBack: '0.00',
		couponDiscount: 0.0,
		couponDetails: '',
		pricingDetails: priceDetails,
		selectedFlight: selectedFlight,
		airlineType: location.state.OneflightData.Airline_Code
			? location.state.OneflightData.Block_Ticket_Allowed
				? 'LCC'
				: 'GDS'
			: location.state.OneflightData.IsLCC
			? 'LCC'
			: 'GDS',
		selectedPax: BookingFormData,
		availableOnNewPrice:
			Number(FareQuotePrice) > Number(OriginalPrice)
				? true
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? true
				: false,
		priceChange: Number(
			Number(Math.round(FareQuotePrice)) - Number(Math.round(OriginalPrice))
		),
		priceValue:
			Number(Math.round(FareQuotePrice)) > Number(Math.round(OriginalPrice))
				? 'PRICEUP'
				: Number(FareQuotePrice) < Number(OriginalPrice)
				? 'PRICEDOWN'
				: 'NOCHANGE',
		ticketSent: 0,
		paymentMode: agentlogin
			? payment === 'offline'
				? 'OFFLINE'
				: 'ONLINE'
			: 'ONLINE',
		agent_id:
			agentlogin && agentlogin.status === 200
				? agentlogin && agentlogin.result.result.id
				: 0,
	};
	dispatch(
		FlightAddBookingDispatch(
			formaddBooking,
			payment,
			payableAmout,
			handlePayment,
			location
		)
	);
	// console.log("add booking", formaddBooking);
};
export default addBooking;
